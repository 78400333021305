import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { ContainerState } from './types';
import { deserializedAllCustomerResonse, deserializeDeleteResponse, deserializedCustomerDetailById } from './deserialize';

// The initial state of the CustomerManagement container
export const initialState: ContainerState = {
  loading: false,
  error: null,
  serverMessages: '',
  customerId: '',
  query: {},
  customerError: null,
  customerLoading: false,
  allCustomers: {
    dataList:[],
    pagination:{},
    currentPage:1,
    totalItems: 0
  },
  singleData:null,
  singleCustomerActivity:null
};

const customerManagementSlice = createSlice({
  name: 'customerManagement',
  initialState,
  reducers: {
    loadAllCustomerRequest(state, action: PayloadAction<any>) {
      state.loading = true;
      state.error = null;
      // state.serverMessages ='';
      state.query = action.payload;
      state.allCustomers={
        ...state.allCustomers,
        currentPage:action.payload.page||1,
      }
    },

    loadAllCustomerSuccess(state, action: PayloadAction<any>) { 
      const response = action?.payload?.data?.data && deserializedAllCustomerResonse(action.payload.data.data);
      const data=response &&  response.datalistList || action.payload.dataList;
      const paginate= response &&  response.pagination || action.payload.pagination;
      // state.allCustomers = {data,paginate};
      state.allCustomers={
        dataList:response.datalistList || action?.payload?.dataList|| [],
        totalItems:response?.pagination?.totalitem||0,
        currentPage:response?.pagination?.currentpage||1
      }
      state.loading = false;
      state.error = null;
    },

    loadAllCustomerFailure(state, action: PayloadAction<any>) {
      
      const response = action?.payload?.data?.data && deserializedAllCustomerResonse(action.payload.data.data);
      state.error = response?.msg || action.payload.msg;
      state.loading = false;
      state.allCustomers = initialState.allCustomers;
    },

    getCustomerDetailByIdRequest(state, action: PayloadAction<any>) {  
      state.error = null;
      state.loading = true;
      state.customerId = action.payload
    },

    getCustomerDetailByIdSuccess(state, action: PayloadAction<any>) {
      const response = action?.payload?.data?.data && deserializedCustomerDetailById(action.payload.data.data);
      // state.serverMessages = response.msg || action.payload.msg;
      state.singleData = response?.customerdetail || action.payload.customerDetail;
      state.singleCustomerActivity = response?.customeractivity || action.payload.customerActivity;
      state.loading = false;
      state.error = null;
    },

    getCustomerDetailByIdFailure(state, action: PayloadAction<any>) { 
      const response = action?.payload?.data?.data && deserializedCustomerDetailById(action.payload.data.data);
      state.error = response.msg || action?.payload?.msg;
      state.serverMessages = '';
      state.loading = false;
    },

    deleteCustomerRequest(state, action: PayloadAction<any>) {
      
      state.error = null;
      state.loading = true;
      state.customerId = action.payload;
      state.serverMessages="";
    },

    deleteCustomerSuccess(state, action: PayloadAction<any>) {
      const response = action?.payload?.data?.data && deserializeDeleteResponse(action.payload.data.data);
      state.customerId = '';
      state.serverMessages = response?.msg || action.payload.msg;
      state.loading = false;
      state.error = null;
    },

    deleteCustomerFailure(state, action: PayloadAction<any>) { 
      const response = action?.payload?.data?.data && deserializeDeleteResponse(action.payload.data.data);
      state.error = response || action.payload.msg;
      state.serverMessages = '';
    },
  

    clearStateMessages(state) {
      state.serverMessages = '';
      state.error = null;
    },
  },
});

export const {
  actions: customerManagementActions,
  reducer,
  name: sliceKey,
} = customerManagementSlice;
