/* eslint-disable */
import Register from 'proto/build/admin_pb';
import User from 'proto/build/user_pb';

export const serializeRegiserUserData = data => {
  try {
    const registerData = new Register.RegisterAdmin();
    registerData.setFirstname(data.firstName);
    registerData.setLastname(data.lastName);
    registerData.setEmail(data.email);
    registerData.setPhone(data.phone);
    registerData.setType(User.RoleType[data.type]);
    registerData.setPassword(data.password);
    registerData.setIsactive(User.ActiveStatus[data.isActive]);

    return registerData.serializeBinary();
  } catch (err) {
    console.log(err, '<<<<<<error');
    throw err;
  }
};

export const serializeEditUserData = data => {
  try {
    const updateData = new Register.EditAdmin();
    // const userData = new User.RoleType();
    updateData.setFirstname(data.firstName);
    updateData.setLastname(data.lastName);
    updateData.setEmail(data.email);
    updateData.setPhone(data.phone);
    updateData.setType(User.RoleType[data.type]);
    data.password &&
      data.password !== '' &&
      updateData.setPassword(data.password);
    updateData.setIsactive(User.ActiveStatus[data.isActive]);
    updateData.setUserid(data.userId);

    return updateData.serializeBinary();
  } catch (err) {
    console.log(err, '<<<<<<error');
    throw err;
  }
};
