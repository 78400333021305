/**
 * Asynchronously loads the component for HomePage
 */

import { lazyLoad } from 'utils/loadable';

export const BannerModule = lazyLoad(
  () => import('./index'),
  module => module.BannerModule,
);
