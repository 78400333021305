/* eslint-disable */
import OrderPB from 'proto/build/order_pb';

export const deserializedAllProductOrderResonse = data => {
  try {
    const deserializedData = OrderPB.OrdersListResponse.deserializeBinary(
      data,
    ).toObject();

    return deserializedData;
  } catch (err) {
    console.log(err, '<<<<<<error');
    throw err;
  }
};
