import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state.messageTemplate || initialState;

export const selectUsers = createSelector(
  [selectDomain],
  state => state,
);

export const selectLoading = createSelector(
  [selectDomain],
  state => state.loading,
);

export const selectError = createSelector(
  [selectDomain],
  state => state.error,
);

export const selectallMessageTemplate = createSelector(
  [selectDomain],
  state => state.allMessageTemplates,
);

export const selectMessageId = createSelector(
  [selectDomain],
  state => state.messageId,
);

export const selectServerMessage = createSelector(
  [selectDomain],
  state => state.serverMessages,
);

export const selectQuery = createSelector(
  [selectDomain],
  state => state.query,
);

export const selectSingleData = createSelector(
  [selectDomain],
  state => state.detailInfo,
);