/**
 * Asynchronously loads the component for HomePage
 */

import { lazyLoad } from 'utils/loadable';

export const CreateNewCustomer = lazyLoad(
  () => import('./index'),
  module => module.CreateNewCustomer,
);
