import React, { useState } from 'react';
import { Input, InputAppend, InputGroup } from '../FormComponents/FormElements';



interface Props {
  value: any;
  name: any;
  placeholder: any;
  onChange: any;
  onBlur?: any;
  min?: number;
  max?: number;
  required?: boolean;
}

function PasswordInputField({
  value,
  name,
  placeholder,
  onChange,
  required,
  onBlur,
  min,
  max,
}: Props) {
  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordChecked = () => {
    setShowPassword(!showPassword);
  };
  return (
    <>
      <InputGroup>
        <Input
         onCopy={(e)=>e.preventDefault()}
          onPaste={(e)=>e.preventDefault()}
          maxLength={30}
          type={showPassword === false ? 'password' : 'text'}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          required={required}
          min={min || 6}
          max = {max || 30}
          autoComplete="new-password"
        />
        <InputAppend>
          <span onClick={handlePasswordChecked}>
            {/* <i className={!showPassword ? 'icon-eye' : 'icon-eye-off'} /> */}
            {showPassword ? <i className="icon-eye" /> : <i className="icon-eye-off" />}
          </span>
        </InputAppend>
      </InputGroup>
    </>
  );
}

export default PasswordInputField;
