/**
 *
 * Asynchronously loads the component for SocialMediaLinks
 *
 */

import { lazyLoad } from 'utils/loadable';

export const SocialMediaLinksModule = lazyLoad(
  () => import('./index'),
  module => module.SocialMediaLinks,
);
