import { take, takeLatest, fork, put, select, delay } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import API from 'utils/apiHelper';
import { actions } from './slice';
import { selectUserId } from './selectors';
import jwtDecode from 'jwt-decode';
import {logoutApi,uploadFileApi, getAdminDetail,uploadBrandImage,getPrivilege} from '../../../Constants/uris';

export function* logout(action) {
  try {
    if (localStorage.getItem('token')) {
      const Storedtoken = localStorage.getItem('token');
      const decoded = Storedtoken ? jwtDecode(Storedtoken) : {};
      if (decoded !== {}) {
        const userlogout = yield fork(
          API.post(
            logoutApi,
            actions.logoutSuccess,
            actions.logoutFailure,
            {},
            Storedtoken,
            'proto',
            'POST',
          ),
        );
        if (userlogout) {
          yield take(actions.logoutSuccess);
          yield put(push('/'));
          yield put(actions.clearState());
        }
      } else {
        sessionStorage.removeItem('token');
        yield put(actions.logoutSuccess);
        yield put(push('/'));
        yield put(actions.clearState());
      }
    } else {
      sessionStorage.removeItem('token');
      localStorage.removeItem('token');
      yield put(push('/'));
      yield put(actions.clearState());
    }
  } catch (err) {
    yield put(actions.logoutFailure(err));
  }
}

export function* fetchUserDetail(action) {
  try {
    const userData: any = yield select(selectUserId);
    // const serializeData = serializeAdminData(userData);
    const token = localStorage.getItem('token');
    const userInfoData: any = yield fork(
      API.get(
        `${getAdminDetail}?userId=${userData}`,
        actions.fetchUserDetailSuccess,
        actions.fetchUserDetailFailure,
        token,
        '',
      ),
    );
    if (userInfoData) {
      yield take(actions.fetchUserDetailSuccess);
    }
  } catch (err) {
    yield put(actions.fetchUserDetailFailure(err));
  }
}
export function* postImageRequest(action) {
/*   yield delay(1000)
  yield put(actions.uploadImageSuccess({
    file_location:['array']
  })) */
  try {
    const token: any = localStorage.getItem('token');
    const file: any = action.payload;
    const apiResponse: any = yield fork(
      API.multipartPost(
        `${action.payload.isFile?uploadFileApi:uploadBrandImage}`,
        actions.uploadImageSuccess,
        actions.uploadImageFailure,
        '',
        action.payload.file,
        token,
      ),
    );
    if (apiResponse) {
      yield take(actions.uploadImageSuccess);
    }
  } catch (err) {
    yield put(actions.uploadImageFailure(err));
  }
}

export function* getAdminPrivilege(action) {
  try {
    const token = localStorage.getItem('token');
    const apiResponse: any = yield fork(
      API.get(
        `${getPrivilege}`,
        actions.getAdminPrivilegeSuccess,
        actions.getAdminPrivilegeFailure,
        token,
        'proto',
        'GET',
      ),
    );
    if (apiResponse) {
      yield take(actions.getAdminPrivilegeSuccess);
    }
  } catch (err) {
    yield put(actions.getAdminPrivilegeFailure(err));
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* userinfoSaga() {
  // Watches for loadRepos actions and calls getRepos when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(actions.logoutRequest.type, logout);
  yield takeLatest(actions.fetchUserDetailRequest.type, fetchUserDetail);
  yield takeLatest(actions.uploadImageRequest.type, postImageRequest);
  yield takeLatest(actions.getAdminPrivilegeRequest.type, getAdminPrivilege);
}
