import React from 'react';
import styled from 'styled-components';

import ProfileSrc from '../../assets/Baby.tux-800x800.png';

const ProfileWrapper = styled.div`
  text-align:center;
  padding:20px;
`;
const ProfileImg = styled.div`
    height:100%;
    width:100px;
    margin:0 auto;
    object-fit:cover;
    margin-bottom:10px;
    img{
      height:100%;
      width:100%;
      border-radius:50%;
      margin-left:auto;
      margin-right:auto;
    }
`
const ProfileName = styled.p`
  margin:0px;
  font-size:14px;
  display: flex;
  align-items: center;
  justify-content: center;
  color:#fff;
  margin-bottom:2px;
`
const ProfileStatus = styled.p`
font-size:12px;  
color:green;
`

interface Props{
  userInfo: any;
}

function Profile({userInfo}: Props) {
  
    return (
      <ProfileWrapper>
        <ProfileImg >
          <img src={ProfileSrc} alt="profile picture"/>
        </ProfileImg>
    <ProfileName>{userInfo && userInfo.name}</ProfileName>
        <ProfileStatus>Online</ProfileStatus>
      </ProfileWrapper>
    );
};

export default Profile;