/**
 * Asynchronously loads the component for Blogs
 */

import { lazyLoad } from 'utils/loadable';

export const Blog = lazyLoad(
  () => import('./index'),
  module => module.Blog,
);
