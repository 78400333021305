import { superadminRoute } from 'Constants/Constants';
import { menuUUID } from 'Constants/uris'
export const menuConfig: any = [
    {
        order: 1,
        icon: 'icon-grid',
        menuName: "Dashboard",
        route: superadminRoute,
        uuid: menuUUID.dashboard
    },
    {
        order: 2,
        icon: 'icon-users',
        menuName: "Admin Users",
        route: `${superadminRoute}/users`,
        uuid: menuUUID.adminUsers,
    },
    {
        order: 3,
        icon: 'icon-users',
        menuName: "Customers",
        uuid: menuUUID.customers,
        route: `${superadminRoute}/customer-management`,
    },
    {
        order: 4,
        menuName: "Orders",
        icon: 'icon-package',
        uuid: menuUUID.orders,
        route: `${superadminRoute}/order-management`,
    },
    {
        order: 5,
        icon: 'icon-shopping-cart',
        menuName: "Products",
        uuid: menuUUID.products,
        childList: [
            {
                icon: 'icon-shopping-bag',
                menuName: "All Products",
                route: `${superadminRoute}/product`,
                uuid: menuUUID.allProducts
            },
            {
                icon: 'icon-star',
                menuName: "Product Brand",
                route: `${superadminRoute}/product-brand`,
                uuid: menuUUID.productBrand
            },
            {
                icon: 'icon-box',
                menuName: "Product Category",
                route: `${superadminRoute}/product-category`,
                uuid: menuUUID.productCategory
            },
            {
                icon:'icon-home',
                menuName: "Homepage Product",
                route: `${superadminRoute}/homepage-product`,
                uuid: menuUUID.homepageProduct
            },
            {
                icon: 'icon-tag',
                menuName: "Menu Product",
                route: `${superadminRoute}/menu-product-management`,
                uuid: menuUUID.menuProduct
            },
        ]
    },
    {
        order: 6,
        icon:'icon-menu',
        menuName: "Menu Management",
        uuid: menuUUID.menu,
        childList: [
            {
                icon: 'icon-menu',
                menuName: "Setup Menu",
                route: `${superadminRoute}/menu-management`,
                uuid: menuUUID.setupMenu
            },
        ]
    },
    {
        order: 7,
        icon: 'icon-bell',
        menuName: "Notification Message",
        uuid: menuUUID.noitifcationMessage,
        childList: [
            {
                icon: 'icon-send',
                menuName: "Push Notification",
                route: `${superadminRoute}/push-message`,
                uuid: menuUUID.pushNotification
            },
            {
                icon: 'icon-message-square',
                menuName: "SMS",
                route: `${superadminRoute}/sms`,
                uuid: menuUUID.sms
            },
            {
                icon: 'icon-mail',
                menuName: "Email",
                route: `${superadminRoute}/email-message`,
                uuid: menuUUID.email
            },
        ]
    },
    {
        order: 8,
        icon: 'icon-settings',
        menuName: "Settings",
        uuid: menuUUID.settings,
        childList: [
            {
                icon: 'icon-link',
                menuName: "Social Media Accounts",
                route: `${superadminRoute}/social-media-links`,
                uuid: menuUUID.socialMediaLinks
            },
            {
                icon: 'icon-image',
                menuName: "Web Banners",
                route: `${superadminRoute}/banner`,
                uuid: menuUUID.webBanners
            },
            {
                icon: 'icon-layout',
                menuName: "Page Template",
                route: `${superadminRoute}/content-template`,
                uuid: menuUUID.pageTemplate
            },
            {
                icon: 'icon-tag',
                menuName: "Customer FAQ's",
                route: `${superadminRoute}/faqs`,
                uuid: menuUUID.customerFaq
            },
            {
                icon: 'icon-icon',
                menuName: "Notification Templates",
                route: `${superadminRoute}/message-template`,
                uuid: menuUUID.notificationTemplate
            },
            {
                icon: 'icon-settings',
                menuName: "Tax Settings",
                route: `${superadminRoute}/tax-setting`,
                uuid: menuUUID.taxSettings
            },
            {
                menuName: "Change Password",
                icon: 'icon',
                route: `${superadminRoute}/change-password`,
                uuid: menuUUID.changePassword
            },
        ]
    },
    {
        order: 15,
        menuName: "Contact Us",
        icon: 'icon-phone-call',
        uuid: menuUUID.contactUs,
        route: `${superadminRoute}/contact-us`,
    },
]
