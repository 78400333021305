import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { ContainerState } from './types';
import { deserializedAllProductOrderResonse  } from './deserialize';

// The initial state of the OrderManagement container
export const initialState: ContainerState = {
  loading: false,
  error: null,
  serverMessages: '',
  query: {},
  allOrderList: {}
};

const orderManagementSlice = createSlice({
  name: 'orderManagement',
  initialState,
  reducers: {
    loadAllProductOrderRequest(state, action: PayloadAction<any>) {
      state.loading = true;
      state.error = null;
      // state.serverMessages ='';
      state.query = action.payload;
    },

    loadAllProductOrderSuccess(state, action: PayloadAction<any>) {
      const response = action?.payload?.data?.data && deserializedAllProductOrderResonse(action.payload.data.data);
      const data=response &&  response.dataList || action.payload.dataList;
      const paginate= response &&  response.pagination || action.payload.pagination;
      state.allOrderList = {data,paginate};
      state.loading = false;
      state.error = null;

    },

    loadAllProductOrderFailure(state, action: PayloadAction<any>) {
      const response = action?.payload?.data?.data && deserializedAllProductOrderResonse(action.payload.data.data);
      state.error = action.payload.status!==404? response?.msg : action.payload?.msg;
      state.loading = false;
      state.allOrderList = {};
    },

    clearStateMessages(state) {
      state.serverMessages = '';
      state.error = null;
    },
  },
});

export const {
  actions: orderManagementActions,
  reducer,
  name: sliceKey,
} = orderManagementSlice;
