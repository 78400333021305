/* eslint-disable */
import { push } from 'connected-react-router';
import { call, put } from 'redux-saga/effects';
import { BASE_URL } from 'Constants/uris';
import { get, requestJSON, request } from './request';
// import ObjectToFormData from './ObjectToFormData';
import { actions } from '../app/containers/Dashboard/slice'
class API {
  /**
   * Generic api data loader
   */
  static dataLoader(
    apiUri,
    onSuccess,
    onError,
    data,
    token,
    type,
    metaData = '',
    ...actionArguments
  ) {
    return function* () {
      const baseUrl = BASE_URL;
      let requestURL = '';
      if (/^https?:\/\//i.test(apiUri)) {
        requestURL = apiUri;
      } else {
        requestURL = `${baseUrl}${apiUri}`;
      }
      try {
        let options;
        if (data !== undefined) {
          options = {
            method: metaData.toLowerCase() === 'put' ? 'PUT' : 'POST', // PUT requests should have _id in data or should send a string "put" after token
            // body: JSON.stringify(data),
            body: type === 'proto' ? data : JSON.stringify(data),
            headers: {
              // 'Content-Type': 'application/json',
              'Content-Type':
                type === 'proto'
                  ? 'application/octet-stream'
                  : 'application/json',
              'X-Requested-With': 'XMLHttpRequest',
              'Access-Control-Allow-Origin': '*',
              Authorization: token !== undefined ? token : '', //? `${usertoken}` : undefined
            },
          };
        } else {
          options = {
            method: 'GET',
            headers: {
              // 'Content-Type': 'application/json',
              'Content-Type':
                type === 'proto'
                  ? 'application/octet-stream'
                  : 'application/json',
              'X-Requested-With': 'XMLHttpRequest',
              'Access-Control-Allow-Origin': '*',
              Authorization: token !== undefined ? token : '', //? `${usertoken}` : undefined
            },
          };
        }
        const response = yield call(requestJSON, requestURL, options);
        yield put(onSuccess(response, data, metaData, ...actionArguments));
      } catch (err) {
        if (apiUri !== 'auth/login' && err?.response?.status === 401) {
          localStorage.clear()
          yield put(actions.clearState())
          yield put(push('/'));
        } else {
          let error;

          try {
            error = yield call(() => err.response.json());
          } catch (a) {
            error = {
              errors: [
                {
                  code: a.response,
                  msg: a.response,
                },
              ],
            };
          }
          yield put(onError(error, ...actionArguments));
        }
      }
    };
  }

  /*
   * Shorthand GET function
   */
  static get(apiUri, onSuccess, onError, token, type, ...actionArguments) {
    return this.dataLoader(
      apiUri,
      onSuccess,
      onError,
      undefined,
      token,
      type,
      'GET',
      ...actionArguments,
    );
  }
  /*
   * Shorthand POST function
   */
  static post(
    apiUri,
    onSuccess,
    onError,
    data,
    token,
    type,
    metaData,
    ...actionArguments
  ) {
    return this.dataLoader(
      apiUri,
      onSuccess,
      onError,
      data,
      token,
      type,
      metaData,
      ...actionArguments,
    );
  }
  /*
   * Shorthand PUT function
   */
  static put(
    apiUri,
    onSuccess,
    onError,
    data,
    token,
    type,
    metaData = 'put',
    ...actionArguments
  ) {
    return this.dataLoader(
      apiUri,
      onSuccess,
      onError,
      data,
      token,
      type,
      metaData,
      ...actionArguments,
    );
  }

  /*
   * Shorthand PATCH function
   */
  static patch(
    apiUri,
    onSuccess,
    onError,
    data = {},
    token,
    type,
    ...actionArguments
  ) {
    return function* () {
      let requestURL = '';
      if (/^https?:\/\//i.test(apiUri)) {
        requestURL = apiUri;
      } else {
        requestURL = `${BASE_URL}${apiUri}`;
      }
      try {
        const options = {
          method: 'PATCH',
          headers: {
            'Content-Type':
              type === 'proto'
                ? 'application/octet-stream'
                : 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            Authorization: token, //`${token}`
          },
          body: JSON.stringify(data),
        };
        const response = yield call(request, requestURL, options);
        yield put(onSuccess(response, ...actionArguments));
      } catch (e) {
        let error: any
        try {
          error = yield call(() => e.response.json());
        } catch (a) {
          error = {
            errors: [
              {
                code: e.response,
                msg: e.response,
              },
            ],
          };
        }
        yield put(onError(error, ...actionArguments));
      }
    };
  }

  static delete(apiUri, onSuccess, onError, token, type, ...actionArguments) {
    return function* () {
      const requestURL = `${apiUri}`;
      try {
        const options = {
          method: 'DELETE',

          headers: {
            // 'Content-Type': 'application/json',
            'Content-Type':
              type === 'proto'
                ? 'application/octet-stream'
                : 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Access-Control-Allow-Origin': '*',
            Authorization: token !== undefined ? token : '', //? `${usertoken}` : undefined
          },
        };
        const response = yield call(get, requestURL, options);
        yield put(onSuccess(response, actionArguments));
      } catch (e) {
        let error = null;
        try {
          error = yield call(() => e.response.json());
        } catch (_) {
          let error;
          error = {
            errors: [
              {
                code: e.response,
                msg: e.response,
              },
            ],
          };
        }
        yield put(onError(error, ...actionArguments));
      }
    };
  }



  static multipartPost(
    apiUri,
    onSuccess,
    onError,
    data,
    document,
    token = '',
    metaData = '',
    ...actionArguments
  ) {
    // eslint-disable-next-line func-names
    return function* () {
      const requestURL = `${BASE_URL}${apiUri}`;
      const multipartData = new FormData();
      // multipartData = ObjectToFormData(data, multipartData);
      if (Object.prototype.toString.call(document) === '[object Array]') {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < document.length; i++) {
          multipartData.append('file', document[i]);
        }
      } else {
        multipartData.append('file', document);
      }
      try {
        const options = {
          method: metaData.toLowerCase() === 'put' ? 'PUT' : 'POST',
          body: multipartData,
          headers: {
            processData: false,
            // 'Content-Type': 'multipart/form-data',
            contentType: false,
            'X-Requested-With': 'XMLHttpRequest',
            Authorization: token,
          },
        };
        const response = yield call(request, requestURL, options);
        yield put(onSuccess(response, ...actionArguments));
      } catch (e) {
        let error;
        try {
          error = yield call(() => e.response.json());
        } catch (a) {
          error = {
            status: e.response,
            message: e.response
          };
        }
        yield put(onError(error, ...actionArguments));
      }
    };
  }
}

export default API;
