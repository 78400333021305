import React, { useState, useEffect } from 'react';
import { superadminRoute } from 'Constants/Constants';
import { Sidemenu, Nav, Menu, MenuItem, MenuLink, MenuIcon, Dropdown } from 'app/components/MenuComponent/SideNav';
import {
	FiLogOut,
} from 'react-icons/fi';
import Profile from 'app/components/ProfileCard/Profile';
import { menuConfig } from './menuConfig';
interface Props {
	handleLogout: any;
	userInfo: any;
	showMenu?: any;
	assignPrivilege?: any;
	isRequesting?:any;
	onClickToggleButton?: any;
}

function Sidebar({ handleLogout, userInfo, showMenu, assignPrivilege, onClickToggleButton ,isRequesting}: Props) {

	const [ active, setActive ] = useState('');
	const [ showMenuAdmin, setshowMenuAdmin ] = useState<any>([]);
	
	useEffect(
		() => {
			if(assignPrivilege&& assignPrivilege.dataList&& Object.keys(assignPrivilege?.dataList?.length>0)){
				let filterMenu :any;
				const finalMenu :any = [];
				let subMenuFilter :any= [];
				assignPrivilege.dataList.map((a)=>{
					if(a.uuid){
						filterMenu=menuConfig.filter((b)=>b.uuid===a.uuid)
						filterMenu.length > 0 && finalMenu.push({...filterMenu[0]});
					}
				})
    		    subMenuFilter = JSON.parse(JSON.stringify(finalMenu));
				assignPrivilege.dataList.map((eachAssignMenu)=>{
					subMenuFilter.map((eachSubMenu,eachSubMenuIndex)=>{
						if(eachAssignMenu.uuid===eachSubMenu.uuid&& eachSubMenu.childList?.length>0){
							finalMenu[eachSubMenuIndex].childList=[]
							eachSubMenu.childList.map((eachSubChild)=>{
								eachAssignMenu.childList.map((x)=>{
									if(eachSubChild.route===window.location.pathname){
										setActive(finalMenu[eachSubMenuIndex].menuName)
									};
									eachSubChild.uuid===x.uuid&&finalMenu[eachSubMenuIndex].childList.push({...eachSubChild})
								})
							})
						}
					})
				})
				setshowMenuAdmin(finalMenu)
			}
		},
		[ assignPrivilege ]
	);
	const toggleActiveClass = (menuName) => {
			setActive(menuName===active?"":menuName);
			};
	return (
		<Sidemenu className={showMenu === true ? 'show' : ''}>
			<div>
				<Profile userInfo={userInfo} />
				{isRequesting && <span style={{color:'white'}}>Loading.......</span>}
				<Nav>
					<Menu>
						{showMenuAdmin.sort((a, b) => a.order - b.order).map(
							(eachMenu, eachMenuIndex) =>
								!eachMenu.childList ? (
									<MenuItem
										key={eachMenuIndex + 1 + ''}
										className={window.location.pathname === eachMenu.route ? 'active' : ''}
									>
										<MenuLink to={eachMenu.route} onClick={() => setActive(eachMenu.menuName)}>
										<MenuIcon><i className={eachMenu.icon || "icon-grid"} /></MenuIcon>
											<span>{eachMenu.menuName}</span>
										</MenuLink>
									</MenuItem>
								) : (
									<MenuItem key={eachMenuIndex + 1 + ''} className={active === eachMenu.menuName ? 'active' : ''}>
										<Dropdown as={'span'} onClick={() => toggleActiveClass(eachMenu.menuName)}>
											<MenuIcon>
												<i className={eachMenu.icon || "icon-grid"} />
											</MenuIcon>{' '}
											{eachMenu.menuName}
										</Dropdown>
										<Menu>
											{eachMenu.childList.map((eachChildren, eachChildrenIndex) => (
												<MenuItem key={eachChildrenIndex + 1 + ''}
												className={window.location.pathname === eachChildren.route ? 'active' : ''}
												>
													<MenuLink to={eachChildren.route}>
													<MenuIcon><i className={eachChildren.icon || "icon-grid"} /></MenuIcon>
														<span>{eachChildren.menuName}</span>
													</MenuLink>
												</MenuItem>
											))}
										</Menu>
									</MenuItem>
								)
						)}
						<MenuItem>
							<MenuLink as={'button'} onClick={handleLogout} className="btn__logout">
								<MenuIcon>
									<FiLogOut />{' '}
								</MenuIcon>{' '}
								Logout
							</MenuLink>
						</MenuItem>
					</Menu>
				</Nav>
				</div>
		</Sidemenu>
	);
}

export default Sidebar;
