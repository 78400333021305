import React, { useState, useEffect } from 'react';
import {
  FormGroup,
  FormLabel,
  Form,
} from 'app/components/FormComponents/FormElements';
import { Link } from 'react-router-dom';
import { Back } from 'app/components/BaseElements/BaseElements';
import { useSelector, useDispatch } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import {
  sliceKey,
  reducer,
  customerManagementActions as actions,
} from './slice';
import { customerManagementSaga } from './saga';
import {
  selectSingleCustomerDetails,
  selectSingleCustomerActivity,
} from './selectors';
import * as ReactBootstrap from 'react-bootstrap';

import DashboardHeader from 'app/components/DashboardHeader';
import { Card } from 'app/components/CardComponents';

interface Props {
  match: any;
}

const ViewCustomerDetail: React.FunctionComponent<Props> = props => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: customerManagementSaga });

  const dispatch = useDispatch();

  const [userData, setUserData] = useState<any>({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    password: '',
    confirmPassword: '',
    type: ', ',
    isActive: '',
  });

  let singleCustomer = useSelector(selectSingleCustomerDetails);
  let singleCustomerActivity = useSelector(selectSingleCustomerActivity);
  useEffect(() => {
    if (singleCustomer && Object.keys(singleCustomer).length > 0)
      setUserData({
        ...singleCustomer,
        phoneNumber: singleCustomer.phone,
        isActive:
          singleCustomer.active === '1' || singleCustomer.active === 'ACTIVE'
            ? 'ACTIVE'
            : 'INACTIVE',
      });
  }, [singleCustomer]);

  useEffect(() => {
    const userId = props.match.params.id;
    if (userId) dispatch(actions.getCustomerDetailByIdRequest(userId));
    else {
      setUserData({});
    }
  }, []);
  return (
    <>
      <DashboardHeader
        breadCrumb={'Customers'}
        method={'View Details'}
        link={'/customer-management'}
      />
      <Card>
        <ReactBootstrap.Container fluid>
          <ReactBootstrap.Row>
            <ReactBootstrap.Col>
              <Form>
                <ReactBootstrap.Row>
                  <ReactBootstrap.Col lg={6}>
                    <FormGroup>
                      <FormLabel> First Name:</FormLabel>
                      <p>
                        <b>{userData.firstname || userData.firstName}</b>
                      </p>
                    </FormGroup>
                  </ReactBootstrap.Col>
                  <ReactBootstrap.Col lg={6}>
                    <FormGroup>
                      <FormLabel>Last Name:</FormLabel>
                      <p>
                        <b>{userData.lastname || userData.lastName}</b>
                      </p>
                    </FormGroup>
                  </ReactBootstrap.Col>
                  <ReactBootstrap.Col lg={6}>
                    <FormGroup>
                      <FormLabel>Email:</FormLabel>
                      <p>
                        <b>{userData.email}</b>
                      </p>
                    </FormGroup>
                  </ReactBootstrap.Col>
                  <ReactBootstrap.Col lg={6}>
                    <FormGroup>
                      <FormLabel>Phone Number:</FormLabel>
                      <p>
                        <b>{userData.phoneNumber}</b>
                      </p>
                    </FormGroup>
                  </ReactBootstrap.Col>

                  <ReactBootstrap.Col lg={6}>
                    <FormGroup>
                      <FormLabel>Status:</FormLabel>
                      <p>
                        <b>{userData.status}</b>
                      </p>
                    </FormGroup>
                  </ReactBootstrap.Col>
                  <ReactBootstrap.Col lg={6}>
                    <FormGroup>
                      <FormLabel>Is Active:</FormLabel>
                      <p>
                        <b>{userData.isActive}</b>
                      </p>
                    </FormGroup>
                  </ReactBootstrap.Col>
                  <ReactBootstrap.Col lg={6}>
                    <FormGroup>
                      <FormLabel>Total Order:</FormLabel>
                      <p>
                        <b>
                          {(singleCustomerActivity &&
                            singleCustomerActivity.totalorders) ||
                            ''}
                        </b>
                      </p>
                    </FormGroup>
                  </ReactBootstrap.Col>
                  <ReactBootstrap.Col lg={6}>
                    <FormGroup>
                      <FormLabel>Spent Amount:</FormLabel>
                      <p>
                        <b>
                          RS{' '}
                          {singleCustomerActivity &&
                            singleCustomerActivity.spentamount}
                        </b>
                      </p>
                    </FormGroup>
                  </ReactBootstrap.Col>
                  <ReactBootstrap.Col lg={6}>
                    <FormGroup>
                      <FormLabel>Signed Up with:</FormLabel>
                      <p>
                        <b>
                          {(singleCustomer &&
                            singleCustomer.signupmedium) ||
                            ''}
                        </b>
                      </p>
                    </FormGroup>
                  </ReactBootstrap.Col>
                </ReactBootstrap.Row>
              </Form>
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>
        </ReactBootstrap.Container>
      </Card>{' '}
      <Link to={`/admin/dashboard/customer-management`}>
        <Back>Back </Back>
      </Link>
    </>
  );
};

export default ViewCustomerDetail;
