import React, { useState, useEffect } from 'react';
import {
  FormGroup,
  FormLabel,
  Form,
} from 'app/components/FormComponents/FormElements';
import { Link } from 'react-router-dom';
import { Back } from 'app/components/BaseElements/BaseElements';
import { useSelector, useDispatch } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { sliceKey, reducer, usersActions } from './slice';
import { usersSaga } from './saga';
import { singleUserData, selectErrorResponse,selectLoading } from './selectors';
import * as ReactBootstrap from 'react-bootstrap';
import Spinner from 'app/components/Spinner'

import DashboardHeader from 'app/components/DashboardHeader';
import { Card } from 'app/components/CardComponents';
import { toast } from 'react-toastify';

/* eslint-disable */
import AdminRpc from 'proto/build/admin_rpc_pb';

interface Props {
  match: any;
}

const CreateUserForm: React.FunctionComponent<Props> = props => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: usersSaga });

  const dispatch = useDispatch();

  const [userData, setUserData] = useState<any>({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    password: '',
    confirmPassword: '',
    type: ', ',
    isActive: '',
  });

  let singleUser = useSelector(singleUserData);
  const isLoading = useSelector(selectLoading);
  let errorResponse = useSelector(selectErrorResponse);

  useEffect(() => {
    if (singleUser && Object.keys(singleUser).length > 0) {
      setUserData({
        ...singleUser,
        phoneNumber: singleUser.phone,
        confirmPassword: singleUser.password,
        isActive:
          singleUser.isActive === '1' || singleUser.isActive === 'ACTIVE'
            ? 'ACTIVE'
            : 'INACTIVE',
      });
    }  
  }, [singleUser]);

  useEffect(() => {
    if (errorResponse) {
      // console.log(singleUser.status);
      setUserData({});
      toast.error(errorResponse)
    }
  }, [errorResponse]);

  useEffect(() => {
    const userId = props.match.params.id;
    if (userId) dispatch(usersActions.getUserByIdRequest(userId));
    else {
      setUserData({});
    }
  }, []);
console.log(userData,'<<')
  return (
    <>
      <DashboardHeader
        breadCrumb={'Admin Users'}
        method={'View Detail'}
        link={'/users'}
      />
      <Card>
        <ReactBootstrap.Container fluid>
           <ReactBootstrap.Row>
                <ReactBootstrap.Col>
                  <Form>
                    <ReactBootstrap.Row>
                      <ReactBootstrap.Col lg={6}>
                        <FormGroup>
                          <FormLabel> First Name:</FormLabel>
                          <p>
                            <b>{userData.firstname}</b>
                          </p>
                        </FormGroup>
                      </ReactBootstrap.Col>
                      <ReactBootstrap.Col lg={6}>
                        <FormGroup>
                          <FormLabel>Last Name:</FormLabel>
                          <p>
                            <b>{userData.lastname}</b>
                          </p>
                        </FormGroup>
                      </ReactBootstrap.Col>
                      <ReactBootstrap.Col lg={6}>
                        <FormGroup>
                          <FormLabel>Email:</FormLabel>
                          <p>
                            <b>{userData.email}</b>
                          </p>
                        </FormGroup>
                      </ReactBootstrap.Col>
                      <ReactBootstrap.Col lg={6}>
                        <FormGroup>
                          <FormLabel>Phone Number:</FormLabel>
                          <p>
                            <b>{userData.phoneNumber}</b>
                          </p>
                        </FormGroup>
                      </ReactBootstrap.Col>

                      <ReactBootstrap.Col lg={6}>
                        <FormGroup>
                          <FormLabel>User Role:</FormLabel>
                          <p>
                            <b>{userData.type}</b>
                          </p>
                        </FormGroup>
                      </ReactBootstrap.Col>
                      <ReactBootstrap.Col lg={6}>
                        <FormGroup>
                          <FormLabel>Status:</FormLabel>
                          <p>
                            <b>{userData.status}</b>
                          </p>
                        </FormGroup>
                      </ReactBootstrap.Col>
                      <ReactBootstrap.Col lg={6}>
                        <FormGroup>
                          <FormLabel>Is Active:</FormLabel>
                          <p>
                            <b>{userData.isactive}</b>
                          </p>
                        </FormGroup>
                      </ReactBootstrap.Col>
                    </ReactBootstrap.Row>
                  </Form>
                </ReactBootstrap.Col>
            </ReactBootstrap.Row> 
        </ReactBootstrap.Container>
      </Card>
      <Link to={`/admin/dashboard/users`}>
        <Back>Back
        {isLoading && (
                            <span className="ml-1">
                              <Spinner  />
                            </span>
                          )}
          </Back>
      </Link>
    </>
  );
};

export default CreateUserForm;
