/* eslint-disable */
import AdminUserRPC from 'proto/build/admin_rpc_pb';
import AuthRPC from 'proto/build/auth_rpc_pb';
import AdminProfile from 'proto/build/admin_profile_pb';
import CommonResponse from 'proto/build/common_pb';

export const deserializeUserResonse = data => {
  try {
    const deserializedData = AdminUserRPC.AdminBaseResponse.deserializeBinary(
      data,
    ).toObject();
    return deserializedData;
  } catch (err) {
    console.log(err, '<<<<<<error');
    throw err;
  }
};

export const deserializedLogOutResonse = data => {
  try {
    const deserializedData = AuthRPC.AuthBaseResponse.deserializeBinary(
      data,
    ).toObject();
    return deserializedData;
  } catch (err) {
    console.log(err, '<<<<<<error');
    throw err;
  }
};

export const deserializedPrivilegeResonse = data => {
  try {
    const deserializedData = AdminProfile.MenuPrivilegeListResponse.deserializeBinary(
      data,
    ).toObject();
    return deserializedData;
  } catch (err) {
    console.log(err, '<<<<<<error');
    throw err;
  }
};

export const deserializeCommonResponse = data => {
  try {
    const deserializedData = CommonResponse.ResponsePayload.deserializeBinary(
      data,
    ).toObject();
    return deserializedData;
  } catch (err) {
    console.log(err, '<<<<<<error');
    throw err;
  }
};
