import styled from 'styled-components';
import { theme } from '../BaseElements/Theme';

export const AncorTag = styled.a`
  color: ${p => p.theme.primary};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    opacity: 0.8;
  }

  &:active {
    opacity: 0.4;
  }
`;

//  form

export const Form = styled.form`
  margin: 0px;
  width: 100%;
`;

// form label

export const FormLabel = styled.label`
  font-weight: normal;
  margin: 0;
  padding: 0;
  color: ${theme.textColor};
`;

export const FormLabelModal = styled.label`
  font-weight: normal;
  margin: 0;
  padding: 0;
  text-align: left;
  color: ${theme.textColor};
`;

// form group

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.25em;

  ${FormLabel} {
    margin-bottom: 0.35rem;
    margin-left: 0;
  }
`;

//view Form Group
export const ViewFormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.25em;
  display: block;
  ${FormLabel} {
    margin-bottom: 0.35rem;
    margin-left: 0;
  }
`;

// text input

export const Input = styled.input`

  border: 1px solid rgba(221, 221, 221, 1);
  border-radius: 4px;
  color: ${theme.textColor};
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: ${p => p.theme.background};
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  outline: none;
  padding: 0.5em 1em;

  // &::placeholder {

  // }
`;

// input group

export const InputGroup = styled.div`
  display: flex;
  align-items: center;

  ${Input} {
    flex: 1;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-right: none;
  }
`;
export const InputAppend = styled.span`
  height: 100%;
  width: 30px;
  text-align: center;
  background: #fff;
  padding: 8px;
  border: 1px solid rgba(221, 221, 221, 1);
  border-left: none;
  border-radius: 4px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
`;

export const Select = styled.select``;

// checkbox and radio buttons group

export const CheckGroup = styled.div`
  display: flex;
  align-items: center;

  ${Input} {
    width: 20px;
    height: 20px;
    padding: 5px;
  }
  ${FormLabel} {
    margin-bottom: 0;
    margin-left: 5px;
  }
`;

// invalid messages
export const InvalidMessage = styled.span`
  color: ${theme.semanticRed};
  font-size: 14px;
  padding: 5px 0;
`;
export const TextArea = styled.textarea`
  border: 1px solid rgba(221, 221, 221, 1);
  border-radius: 4px;
  color: ${theme.textColor};
  padding: 0.5em 1em;
  
`