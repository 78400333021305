import styled from 'styled-components';
// import {Input} from './FormElements';
import React from 'react';

const CheckWrapper = styled.span`
display: block;
position: relative;
padding-left: 30px;
margin-bottom: 12px;
cursor: pointer;
font-size: 16px;
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;

input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark{
  position: absolute;
top: 0;
left: 0;
height: 25px;
width: 25px;
border-radius:4px;
background-color: #fff;
border:1px solid #d6d6d6;
&:after{
  content: "";
  position: absolute;
  // display: none;
  left: 9px;
  top: 5px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
}


&:hover{
  input {
      ~ .checkmark{
      background:#ccc;
      
    }
  }
}
input.checked ~ .checkmark {
  background:#2680EB;
  &::after{
    display: block;
  }
 
`
interface Props {
  label?: any,
  name?:any,
  handleStatusChange?: any,
  checked?: any,
}

const Checkbox : React.FunctionComponent<Props> = ({label, name, handleStatusChange, checked}) => {
    return (
        <CheckWrapper onClick={(e) => handleStatusChange(name, e)}>
            {label}
          <input type="checkbox"  name = {name} className={checked === true ? "checked" : ""} />
        
          <span className="checkmark"/>
       </CheckWrapper>
    );
};

export default Checkbox