/**
 *
 * Asynchronously loads the component for ContentTemplate
 *
 */

 import { lazyLoad } from 'utils/loadable';

 export const CreateSocialMediaLinks = lazyLoad(
   () => import('./index'),
   module => module.CreateSocialMediaLinks,
 );
 