/**
 *
 * Asynchronously loads the component for MessageManagement
 *
 */

import { lazyLoad } from 'utils/loadable';

export const CreatPushMessage = lazyLoad(
  () => import('./index'),
  module => module.CreatPushMessage,
);
