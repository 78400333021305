/* eslint-disable */
import CustomerRpc from 'proto/build/customer_pb';
import DeleteResponse from 'proto/build/common_pb';

export const deserializedAllCustomerResonse = data => {
  try {
    const deserializedData = CustomerRpc.CustomerListResp.deserializeBinary(
      data,
    ).toObject();
    // const datas = CustomerRpc.CustomerListResp.deserializeBinary(
    //   new Uint8Array(data),
    // ); //for debug purpose
    // const reds = datas.toObject(); //for debug purpose
    // console.log('response >>>>>>>>>>>>>>>', reds);

    return deserializedData;
  } catch (err) {
    console.log(err, '<<<<<<error');
    throw err;
  }
};

export const deserializedCustomerDetailById = data => {
  try {
    const deserializedData = CustomerRpc.ViewCustomerResp.deserializeBinary(
      data,
    ).toObject();
    // const datas = CustomerRpc.CustomerListResp.deserializeBinary(
    //   new Uint8Array(data),
    // ); //for debug purpose
    // const reds = datas.toObject(); //for debug purpose
    // console.log('response >>>>>>>>>>>>>>>', reds);

    return deserializedData;
  } catch (err) {
    console.log(err, '<<<<<<error');
    throw err;
  }
};

export const deserializeDeleteResponse = data => {
  try {
    const deserializedData = DeleteResponse.ResponsePayload.deserializeBinary(
      data,
    ).toObject();
    return deserializedData;
  } catch (err) {
    console.log(err, '<<<<<<error');
    throw err;
  }
};
