const combinedValidation = (field, attribute, value, event) => {
	// const emailRe = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	// const minRe = /min:[0-9]+$/;
	// const maxRe = /max:[0-9]+$/;
	const element = document.getElementsByName(field);

	switch (attribute) {
		case 'required':
			return value.length > 0 ? '' : `Please fill this field.`;
		// case 'email':
		//   return emailRe.test(value) ? '' : 'Please enter a valid email';
		case 'min':
			const minValue = element[0].getAttribute('min');
			return value.length >= parseInt(minValue || '0')
				? ''
				: `This value needs to be at least ${minValue} characters`;
		case 'max':
			const maxValue = element[0].getAttribute('max');
			return value.length <= parseInt(maxValue || '1000')
				? ''
				: `This value cannot be more than ${maxValue} characters`;
		default:
			return '';
	}
};

export const basicValidation = (field, value, event) => {
	let basicError = '';
	const element = document.getElementsByName(field);
	// if (event.target.required || ) {
	//   basicError = combinedValidation('required', value, event);
	// }
	if (element && element[0].getAttribute('required') !== null && basicError === '') {
		basicError = combinedValidation(field, 'required', value, event);
	}
	if (element && element[0].getAttribute('min') && basicError === '') {
		basicError = combinedValidation(field, 'min', value, event);
	}
	if (element && element[0].getAttribute('max') && basicError === '') {
		basicError = combinedValidation(field, 'max', value, event);
	}
	return basicError;
};

export const emailValidation = (event) => {
	const email = event.value;
	if (email && !/\S+@\S+\.\S+/.test(email)) {
		return 'Email address is invalid';
	}
	else if(!email){
		return `Email can't be blank`
	}
};

export const phoneValidation = (event) => {
	const phoneNumber = event.value;
	if (phoneNumber && (!(/^9(6|7|8)\d{8}$/).test(phoneNumber) && !(/^(01)-(4|5|6)\d{6}$/).test(phoneNumber) && !(/^[1-9]{1}\d{1}-(4|5|6)\d{5}$/).test(phoneNumber))) {
		return 'Invalid Phone number e.g. 98XXXXXXXX / 01-4XXXXXX / 21-4XXXXX';
	} else if(!phoneNumber){
		return `Phone Number can't be blank`
	}
};

export const passwordValidation = (event) => {
	const password = event.value;
	if (password && !/^(?=.*\d)(?=.*[a-z]).{6,}$/.test(password))
		return 'Password must contain atleast 6 character and one numeric value';
		else if(!password){
			return `Password can't be blank`
		}
};
export const matchValidation = (event, match, text) => {
  const data = event.value;
  if (data !== match) return `${text} do not match`;
};
export const nameValidation = (event) => {
	const name = event.value;
	const {field}=event
	const fieldType={
		firstName:'First name',
		lastName:'Last name'
	}
	if (name && !/^[a-zA-Z ]*$/.test(name)) {
		return 'Incorrect name format, eg: John';
	}
	else if(!name){
		return `${fieldType[field]} can't be blank`
	}
};
// function capitalize(string) {
//   return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
// }

export const clipper = (data, length = 35) => {
	return data && data.length > length ? `${data.substring(0, length)}....` : data;
};

export const checkLink = (value) => {
	return /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'*+,;=.]+$/.test(value);
};

export function validateHours(date) {
	const value = typeof date === 'string' ? new Date(Date.parse(date)) : date;
	return !(
	  value.getDate() === new Date().getDate() &&
	  new Date().getHours() > value.getHours()
	);
  }
  
  export function validateMins(date) {
	const value = typeof date === 'string' ? new Date(Date.parse(date)) : date;
	return !((d = new Date()) =>
	  value.getDate() === d.getDate() &&
	  d.getHours() === value.getHours() &&
	  d.getMinutes() > value.getMinutes())();
  }
  
  export function numberWithDecimal(value) {
	return !!/^[0-9.]*$/.test(value);
  }

export function numberWithDecimal100(value) {
	/* if(!!/^[0-9.]*$/.test(value)){
	return !!/(?<![\d.])(\d{1,2}|\d{0,2}\.\d{1,2}|\d*(\.))?(?![\d.])/.test(value)
	} */
	if (!!/^[0-9.]*$/.test(value)) {
		// /(?:\/)([^#]+)(?=#|$)/
		// /(?:\/)([^#]+)(?=#*)/
		return !!/^(?:|![\d.])(\d{1,2}|\d{0,2}\.\d{1,2}|\d*(\.))?(?![\d.])/.test(
		  value,
		);
	  }
  }

  export const numberWithCommas = x => {
    if (x && (typeof x === 'number')) {
      return x.toLocaleString('en-IN');
    }
    if (x && (typeof x === 'string')) {
      return parseFloat(x).toLocaleString('en-IN');
    }
    return x;
  };
  