import React, { useState, useEffect } from 'react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CustomToaster: any = ({ message, info }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    if (info === 'error') {
      toast.error(message);
    } else {
      toast.success(message);
    }
  }, []);

  if (visible) {
    return (
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        // pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
    );
  }
};

export default CustomToaster;
