/**
 * Asynchronously loads the component for ResetPasswordFlow
 */

import { lazyLoad } from 'utils/loadable';

export const ResetPasswordFlow = lazyLoad(
  () => import('./index'),
  module => module.ResetPasswordFlow,
);
