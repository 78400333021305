import React from 'react';
// import Link from 'react-dom';
import styled from 'styled-components';
import {FiGrid} from 'react-icons/fi';

import {theme} from '../BaseElements/Theme';




// page title, subtitle

 const TitleWrapper = styled.div `
  margin-bottom:30px;
`
 const TitleIcon = styled.span`
    display:inline-block;
    height: 36px;
    width: 36px;
    padding: 2px;
    text-align: center;
    line-height: 34px;
    background:#D9E7F7;
    border-radius:50%;
    color: ${theme.mainColor};
    margin-right:5px;
`


 const Title = styled.p`
    display:flex;
    font-size:20px;
    color:${theme.mainColor};
    font-weight:800;
    margin-bottom:0;
    align-items:center;
    @media screen and (max-width:768px){
      font-size:18px;
    }
` 
//  const SubTitle = styled.p`
//   font-size:16px;
//   color:${theme.textColor};
//   opacity:0.6;
//   margin-bottom:0;
//   @media screen and (max-width:768px){
//     font-size:14px;
//   }
// ` 
interface Props{
  pageTitle?: any;
}

const PageTitle: React.FC<Props> = (props) => {
    return (
        <TitleWrapper>
            
          <Title><TitleIcon><i className="icon-grid"></i></TitleIcon>{props.pageTitle}</Title>
          {/* <SubTitle>Please provide us with your login credentials</SubTitle>      */}
        </TitleWrapper>
    );
};

export default PageTitle;












