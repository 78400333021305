import React, { useState, useEffect } from 'react';
import {
  Input,
  FormGroup,
  FormLabel,
  Form,
  InvalidMessage,
} from 'app/components/FormComponents/FormElements';
import Checkbox from 'app/components/FormComponents/CustomCheckbox';
import { ThemeProvider } from 'styled-components';
import { theme } from 'app/components/BaseElements/Theme';
import { Button } from 'app/components/BaseElements/BaseElements';
import { useSelector, useDispatch } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { sliceKey, reducer, usersActions } from './slice';
import { usersSaga } from './saga';
import { singleUserData, selectErrorResponse,selectLoading } from './selectors';
import * as ReactBootstrap from 'react-bootstrap';
import { toast } from 'react-toastify';

import DashboardHeader from 'app/components/DashboardHeader';
import { Card } from 'app/components/CardComponents';
import PasswordInputField from 'app/components/PasswordInputField';
import CustomToaster from 'app/components/Toaster';
import {
  basicValidation,
  emailValidation,
  phoneValidation,
  passwordValidation,
  matchValidation,
  nameValidation,
} from 'app/components/FormComponents/FormValidationRules';
import Spinner from 'app/components/Spinner'

interface Props {
  match: any;
}

const CreateUserForm: React.FunctionComponent<Props> = props => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: usersSaga });

  const dispatch = useDispatch();

  const [userData, setUserData] = useState<any>({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    password: '',
    confirmPassword: '',
    type: ', ',
    isActive: 'ACTIVE',
  });
  const [errors, setErrors] = useState<any>({});

  let singleUser = useSelector(singleUserData);
  const error = useSelector(selectErrorResponse);
  const isLoading=useSelector(selectLoading);

  useEffect(() => {
    return () => {
      dispatch(usersActions.cleanMessage());
    }
  }, [])

  useEffect(() => {
    if (singleUser && Object.keys(singleUser).length > 0)
      setUserData({
        firstName: singleUser.firstname,
        lastName: singleUser.lastname,
        phoneNumber: singleUser.phone,
        password: '',
        confirmPassword: '',
        type: singleUser.type,
        isActive:
          singleUser.isActive === '1' || singleUser.isactive === 'ACTIVE'
            ? 'ACTIVE'
            : 'INACTIVE',
      });
  }, [singleUser]);

  useEffect(() => {
    const userId = props.match.params.id;
    if (userId) dispatch(usersActions.getUserByIdRequest(userId));
    else {
      setUserData({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        password: '',
        confirmPassword: '',
        type: ', ',
        isActive: 'ACTIVE',
      });
    }
  }, []);

  const onSubmitForm = (evt?: React.FormEvent<HTMLFormElement>) => {
    /* istanbul ignore next  */
    if (evt !== undefined && evt.preventDefault) {
      evt.preventDefault();
      let allValidation = {};
      Object.keys(userData).map(data => {
        let result = validate(data, userData[data]);
        allValidation = { ...allValidation, ...result };
        setErrors(allValidation);
      });

      if (
        Object.keys(errors).length === 0 &&
        Object.keys(allValidation).length === 0
      ) {
        // const { from } = props.location.state || { from: false };
        const userId = props.match.params.id;
        if (userId) {
          let finalUserdata = { ...userData, email: singleUser.email };
          dispatch(
            usersActions.updateUserRequest({
              id: userId,
              userData: finalUserdata,
            }),
          );
        } else dispatch(usersActions.saveUserRequest(userData));
      }
    }
  };

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    evt.persist();
    if (!!errors[evt.target.name] && !!evt.target.value)
      delete errors[evt.target.name];
    setUserData({ ...userData, [evt.target.name]: evt.target.value });
  };
  const onBlur = (evt: React.ChangeEvent<HTMLInputElement>) => {
    // validate(evt.target.name, evt.target.value);
  };
  const validate = (field, value, evt?: any) => {
     let validation = basicValidation(field, value, evt);
    if (validation) {
      let totalError = { ...errors, [field]: validation };
      setErrors(totalError);
    }
    if (field === 'phoneNumber') {
      let phoneVal = phoneValidation({ field, value });
      if (phoneVal) {
        let totalError = { ...errors, [field]: phoneVal };
        setErrors(totalError);
        return totalError;
      }
    }
    if (field === 'email') {
      let emailVal = emailValidation({ field, value });
      if (emailVal) {
        let totalError = { ...errors, [field]: emailVal };
        setErrors(totalError);
        return totalError;
      }
    }
    if (field === 'password'&&!props.match.params.id) {
      let emailVal = passwordValidation({ field, value });
      if (emailVal) {
        let totalError = { ...errors, [field]: emailVal };
        setErrors(totalError);
        return totalError;
      }
    }
    if (field === 'confirmPassword'&&!props.match.params.id) {
      let emailVal = matchValidation(
        { field, value },
        userData.password,
        'Password',
      );
      if (emailVal) {
        let totalError = { ...errors, [field]: emailVal };
        setErrors(totalError);
        return totalError;
      }
    }
    if(field==='type'&&!value){
      let totalError = { ...errors, [field]: `Please Select One` };
        setErrors(totalError);
        return totalError;
    }
    if (field === 'firstName' || field === 'lastName') {
      let firstname = nameValidation({ field, value });
      if (firstname) {
        let totalError = { ...errors, [field]: firstname };
        setErrors(totalError);
        return totalError;
      }
    }
  };

  const handleStatusChange = (
    name: any,
    evt: React.ChangeEvent<HTMLInputElement>,
  ) => {
    evt.persist();
    // if (!!errors[name] && !!evt.target.value)
    //   delete errors[name];
    setUserData({
      ...userData,
      [name]: userData.isActive === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE',
    });
  };

  useEffect(() => {
    if(error){
      toast.error(error);
    }
  }, [error])
  return (
    <>
      <DashboardHeader
        breadCrumb={'Admin Users'}
        method={'Create User'}
        link={'/users'}
      />
      <Card>
        <ReactBootstrap.Container fluid>
          <ReactBootstrap.Row>
            <ReactBootstrap.Col>
              <Form onSubmit={onSubmitForm} autoComplete="off">
                <ReactBootstrap.Row>
                  <ReactBootstrap.Col lg={6}>
                    <FormGroup>
                      <FormLabel>First Name *</FormLabel>
                      <Input
                       type="text"
                        placeholder="First Name"
                        name="firstName"
                        onChange={handleChange}
                        value={userData.firstName || userData.name || ''}
                        onBlur={onBlur}
                        // required={true}
                        min={2}
                        max={10}
                        autoComplete="new-password"
                      />
                      {errors.firstName && (
                        <InvalidMessage>{errors.firstName}</InvalidMessage>
                      )}
                    </FormGroup>
                  </ReactBootstrap.Col>
                  <ReactBootstrap.Col lg={6}>
                    <FormGroup>
                      <FormLabel>Last Name *</FormLabel>
                      <Input
                        type="text"
                        placeholder="Last Name"
                        name="lastName"
                        onChange={handleChange}
                        value={userData.lastName || ''}
                        onBlur={onBlur}
                        // required={true}
                        min={3}
                        max={15}
                        autoComplete="new-password"
                      />
                      {errors.lastName && (
                        <InvalidMessage>{errors.lastName}</InvalidMessage>
                      )}
                    </FormGroup>
                  </ReactBootstrap.Col>
                  {!props.match.params.id && (
                    <ReactBootstrap.Col lg={6}>
                      <FormGroup>
                        <FormLabel>Email *</FormLabel>
                        <Input
                          type="email"
                          placeholder="Email"
                          name="email"
                          onChange={handleChange}
                          value={userData.email || ''}
                          onBlur={onBlur}
                          // required={true}
                          autoComplete="new-password"
                        />
                        {errors.email && (
                          <InvalidMessage>{errors.email}</InvalidMessage>
                        )}
                      </FormGroup>
                    </ReactBootstrap.Col>
                  )}
                  <ReactBootstrap.Col lg={6}>
                    <FormGroup>
                      <FormLabel>Phone Number *</FormLabel>
                      <Input
                        type="text"
                        placeholder="Phone Number"
                        name="phoneNumber"
                        onChange={handleChange}
                        value={userData.phoneNumber || ''}
                        onBlur={onBlur}
                        // required={true}
                        autoComplete="new-password"
                        maxLength={10}
                      />
                      {errors.phoneNumber && (
                        <InvalidMessage>{errors.phoneNumber}</InvalidMessage>
                      )}
                    </FormGroup>
                  </ReactBootstrap.Col>

                  <ReactBootstrap.Col lg={6}>
                    <FormGroup>
                      <FormLabel>Password {userData.id?'':"*"}</FormLabel>
                      <PasswordInputField
                        name="password"
                        placeholder="Password"
                        onChange={handleChange}
                        value={userData.password || ''}
                        onBlur={onBlur}
                        // required={!props.match.params.id}
                      />
                      {errors.password && (
                        <InvalidMessage>{errors.password}</InvalidMessage>
                      )}
                    </FormGroup>
                  </ReactBootstrap.Col>
                  <ReactBootstrap.Col lg={6}>
                    <FormGroup>
                      <FormLabel>Confirm Password</FormLabel>
                      <PasswordInputField
                        name="confirmPassword"
                        placeholder="Confirm Password"
                        onChange={handleChange}
                        value={userData.confirmPassword || ''}
                        onBlur={onBlur}
                        // required={!props.match.params.id}
                      />
                      {errors.confirmPassword && (
                        <InvalidMessage>
                          {errors.confirmPassword}
                        </InvalidMessage>
                      )}
                    </FormGroup>
                  </ReactBootstrap.Col>
                  <ReactBootstrap.Col lg={6}>
                    <FormGroup>
                      <FormLabel>User type</FormLabel>
                      <ReactBootstrap.Form.Control
                        as="select"
                        custom
                        onChange={handleChange}
                        name="type"
                        value={userData.type || ''}
                        // required={true}
                      >
                        <option value={''}>Select User Type</option>
                        <option value={'SUPER_ADMIN'}>Super Admin</option>
                        <option value={'MANAGER'}>Manager</option>
                        <option value={'PRODUCT_MANAGER'}>
                          Product Manager
                        </option>
                        <option value={'SHOP_MANAGER'}>Shop Manager</option>
                      </ReactBootstrap.Form.Control>
                      {errors.type && (
                        <InvalidMessage>{errors.type}</InvalidMessage>
                      )}
                    </FormGroup>
                  </ReactBootstrap.Col>
                  <ReactBootstrap.Col lg={6}>
                    <ReactBootstrap.Form.Group>
                      <FormLabel>Status</FormLabel>

                      {/* <ReactBootstrap.Form.Check
                        type="checkbox"
                        label="Is Active"
                        name="isActive"
                        onChange={handleStatusChange}
                        checked={userData.isActive == 'ACTIVE'}
                      /> */}
                      <Checkbox
                        label="Is Active"
                        name="isActive"
                        handleStatusChange={handleStatusChange}
                        checked={userData.isActive === 'ACTIVE'}
                      />
                    </ReactBootstrap.Form.Group>
                  </ReactBootstrap.Col>

                  <ReactBootstrap.Col lg={12}>
                    <ThemeProvider theme={theme}>
                      <Button disabled={isLoading} as={'button'} type="submit">
                      Submit
                          {isLoading && (
                            <span className="ml-1">
                              <Spinner  />
                            </span>
                          )}
                      </Button>
                    </ThemeProvider>
                  </ReactBootstrap.Col>
                </ReactBootstrap.Row>
              </Form>
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>
        </ReactBootstrap.Container>
      </Card>
    </>
  );
};

export default CreateUserForm;
