import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { theme } from '../BaseElements/Theme';
// import { FiLock } from 'react-icons/fi';

import Carret from '../../assets/down-arrow.png';

export const Sidemenu = styled.aside`
height: 100%;
width: 260px;
position: fixed;
color:black;
z-index: 1;
top: 0;
left: 0;
background-color: #f0f0f0;
overflow-x: hidden;
padding:0;
padding-top: 90px;
background-color: #001b34;
transition:0.2s all ease-in-out;
 > div{
   position:relative;
   height:100%;
 }

 ::-webkit-scrollbar-track 
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}
::-webkit-scrollbar{
	width: 5px;
	background-color: #F5F5F5;
}
::-webkit-scrollbar-thumb 
{
	background-color:${theme.mainColor};
	border: none;
}

@media screen and (max-width:768px){
  left:-300px;
  box-shadow:2px 0px 16px rgba(0,0,0,0.2); 
}
&.show{
  left:0;
}

`

export const Menu = styled.ul`


`



export const MenuLink = styled(Link)`
text-decoration:none !important;
background:none;
border:none;
font-size: 16px;
font-weight: normal;
font-stretch: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: normal;
text-align: left;
color: #fff;
padding:15px;
display:flex;
align-items:center;

  &:hover{
    color:#fff;
    background:#002242;
    cursor:pointer;
    text-decoration:none;
  }
  &:focus{
    outline:none;
  }

  &.btn__logout{
    width:100%;
  }

`

export const Dropdown = styled(MenuLink)`

    position:relative;
    transition:0.2s all ease-in-out;
    &:after{
      position:absolute;
      content:'';
      height:15px;
      width:20px;
      background:red;
      background: url(${Carret}) no-repeat;   
      right:15px;
      top:50%;
      transform:translateY(-50%) rotate(-90deg);
      transition:0.2s all ease-in-out;
    }


`;

export const MenuItem = styled.li`

transition:0.2s all ease-in-out;
> ${Menu}{
  background:transparent;
  background:#002242;
  overflow:hidden;
  li{
    a{
      transition:0.2s all ease-in-out;
      margin-top:-100%;
      padding-left:35px;
      &:hover{
        background:#05284a;
      }
    }
  }
 }
 &.active{
   background:#002242;
    ${Menu}{
      li{
        a{
          margin-top:0;
        }
        &.active{
          background: #05284a;
        }
      }
    }
    ${Dropdown}{
      &:after{
        transform:translateY(-50%) rotate(0deg);
      }
    }
}
`


export const MenuIcon = styled.span`
  margin-right:5px;
  line-height:16px;
`
export const Nav = styled.nav`

${MenuItem}{
  &:before{
    display:none;
  }
}
` 
export const Logout =styled.div`
  display:block;
  // background-color: #001b34;
  background:red;
  position:absolute;
  bottom:0;
  left:0;
  right:0;
  padding:10px 20px;
`

export const ExitButton = styled.button`
  background:none;
  border:none;
  color:#fff;


`
