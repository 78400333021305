import styled from "styled-components"
import {theme} from './Theme';

// main-wrapper 
export const MainWrapper =styled.section`
`

export const MainContent = styled.main `
  flex:1;
  margin-left: 260px;
  padding:40px;
  padding-top:90px;
  @media screen and (max-width:1024px){
    padding:30px;
    padding-top:80px;
  }
  @media screen and (max-width:768px){
    margin-left: 0px;
  }
`

// alert message

export const Alert = styled.div `
  padding:15px 15px;
  color : ${theme.semanticRed};
  margin-bottom:20px;
  border: 1px solid ${theme.semanticRed};
  font-weight:600;
  border-radius:5px;
  background:#ffe9e8;
`

// buttons

export const Button = styled.button`
  font-size: 1em;
  margin: 0;
  padding: 0.5em 1em;
  border-radius: 3px;
  border:none;
  color: #fff;
  background-color: ${theme.mainColor};
  transition:0.2s all ease-in-out;
  line-height: 1.5;
  box-shadow:0px 0px 0px 0px rgba(0,0,0,0,0);
  display:inline-block;
  
  &:hover{
    text-decoration:none;
    color:#fff;
    cursor:pointer;
    opacity:0.9;
  }
  &:active, &:focus{
    box-shadow:0px 0px 0px 3px rgba(38,128,235,0.2);
  }

  &[aria-disabled="true"], &[disabled] {
    cursor: not-allowed;
    opacity:0.5;
  }


`

export const Back = styled(Button)`
  background:#898989;
  padding:5px 10px;
  font-size:14px;
  margin: 10px 0;
`

export const LinkButton =styled.a`
  border:0px;
  background:none;
  padding:10px;
  color:${theme.mainColor}
  width:100%;
  text-align:center;
  &:hover{
    cursor:pointer;
  }
`

// interface TitleProps {
//   readonly isActive: boolean;
// };

//  export const Titles = styled.h1<TitleProps>`
//   color: ${props => props.isActive ? 'red' : 'blue'};
// `
// page title, subtitle

export const TitleWrapper = styled.div `
display:flex;
margin-bottom:30px;

`
export const TitleIcon = styled.span`
  display:inline-block;
  height :40px;
  width:40px;
  text-align:center;
  background:#D9E7F7;
  color: ${theme.mainColor};
`


export const Title = styled.p`

font-size:20px;
color:${theme.mainColor};
font-weight:800;
margin-bottom:0;
@media screen and (max-width:768px){
  font-size:18px;
}

` 
export const SubTitle = styled.p`
font-size:16px;
color:${theme.textColor};
opacity:0.6;
margin-bottom:0;
@media screen and (max-width:768px){
  font-size:14px;
}
` 
