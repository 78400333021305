import { put, takeLatest, fork, take } from 'redux-saga/effects';
import { orderManagementActions as actions } from './slice';
// import { serializeRegiserUserData } from './serialize';
import API from 'utils/apiHelper';
import { viewAllOrders } from 'Constants/uris';
import { queryFunction } from 'utils/apiQueryHelper';

export function* loadAllProductOrderRequest(action) {
  try {
    const token = localStorage.getItem('token');
    const orderFields={'paymentstatus':'payment_status','orderstatus':'order_status'}
    const {
      page,
      perpage,
      date,
      orderField,
      orderType,
      name,
      product_name,
      orderStatus,
    } = action.payload;
    const querDate = date !== null ? date : '';
    const orderStatusFilter=orderStatus?.value?`&filter[order_status]=${orderStatus?.value || ''}`:''
    const query={
      currentpage:page,
      perpage,
      orderField,
      orderType,
      name,
      product_name,
      order_status:orderStatus
    }
    const apiResponse: any = yield fork(
      API.get(
        // `${viewAllOrders}?filter[name]=${name}&filter[product_name]=${product_name}&currentpage=${page}&perpage=${perpage}&orderby[${orderField}]=${orderType}`,
        `${viewAllOrders}${queryFunction(query,[],[],'orderby')}`,
        // `${viewAllOrders}?filter[name]=${name}&filter[product_name]=${product_name}${orderStatusFilter}&orderby[${orderFields[orderField] || orderField}]=${orderType}&currentpage=${page}&perpage=${perpage}`,
        // https://ehubdev.bitsbeat.com/api/admin/v1/order-management/view-order-list?filter[name]=&filter[product_name]=&orderby[created_at]=desc&currentpage=1&perpage=10
        // http://54.151.205.27:3000/api/admin/v1/order-management/view-order-list?currentpage=1&perpage=10&orderby[created_at]=desc
        actions.loadAllProductOrderSuccess,
        actions.loadAllProductOrderFailure,
        token,
        'proto',
        'GET',
      ),
    );
    if (apiResponse) {
      yield take(actions.loadAllProductOrderSuccess);
    }
  } catch (err) {
    yield put(actions.loadAllProductOrderFailure(err));
  }
}

export function* orderManagementSaga() {
  yield takeLatest(
    actions.loadAllProductOrderRequest.type,
    loadAllProductOrderRequest
  );
  
}
