import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { UsersState } from './types';
import { deserializedAllAdminResonse, deserializedSingleUserResonse , deserializedDeleteResonse} from './deserialize';
// The initial state of the Users container
export const initialState: UsersState = {
  allUsersData:  {
    dataList:[],
    pagination:{},
    currentPage:1,
    totalItems: 0
  },
  loading: false,
  error: null,
  errorResponse:'',
  singleUserData:{},
  userMessage:null,
  deletedId:'',
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    cleanMessage(state){ 
      state.userMessage=null;
      state.deletedId='';
      state.error = null;
      state.errorResponse=null;
    },
    loadAllUsersData(state,  action: PayloadAction<any>) {
      state.loading = true;
      state.singleUserData={};
      state.error = null;
      state.allUsersData={
        dataList:[],
        currentPage:action.payload.page||1,
        totalItems:state.allUsersData.totalItems||0
      }
      state.userMessage ='';
    },
    allUsersDataLoaded(state, action: PayloadAction<any>) {
      const response = action?.payload?.data?.data && deserializedAllAdminResonse(action.payload.data.data); 
      const data=response &&  response.adminlistList || action.payload.adminList;
      // console.log(response,'response')
      state.allUsersData={
        dataList:response.adminlistList || action?.payload?.adminList|| [],
        totalItems:response?.pagination?.totalitem||0,
        currentPage:response?.pagination?.currentpage||1
      }
      state.loading = false;
    },
    allUsersDataError(state, action: PayloadAction<any>) { 
      const response =action.payload?.data?.data&& deserializedAllAdminResonse(action.payload.data.data)||'Server Error';
      state.error = response.msg || action.payload.msg||action.payload.message;
      state.loading = false;
      state.allUsersData=initialState.allUsersData
    },
    deleteUserRequest(state, action: PayloadAction<any>) {
      state.error = null;
      state.userMessage = ''
    },
    deleteUserSuccess(state, action: PayloadAction<any>) {  
      const response = action?.payload?.data?.data && deserializedDeleteResonse(action.payload.data.data);
      state.userMessage = response.msg || action.payload.message||'';
      state.deletedId = response.deletedadmin.userid || action.payload.deletedAdmin.userId;
      state.loading = false;
      state.error = null;
      // const userId = action.payload.deletedAdmin.userId;
      // const allUsersData= useSelector(selectAllUsersData);
    },
    deleteUserFailure(state, action: PayloadAction<any>) {   
      const response = action?.payload?.data?.data && deserializedAllAdminResonse(action.payload.data.data);
      state.error = action.payload.msg;
      state.userMessage = response || action.payload.msg;
      state.usersRegisterData = action.payload;
    },
    

    saveUserRequest(state, action: PayloadAction<any>) {
      state.loading = true;
      state.errorResponse = null;
      state.usersRegisterData = action.payload;
    },
    saveUserSuccess(state, action: PayloadAction<any>) { 
      const response = action?.payload?.data?.data && deserializedSingleUserResonse(action.payload.data.data);
      // state.allUsersData = response || action.payload.adminList;
      state.userMessage = response.msg || action.payload.msg;
      state.loading = false;
    },
    saveUserFailure(state, action: PayloadAction<any>) {
      const response = action?.payload?.data?.data && deserializedSingleUserResonse(action.payload.data.data);
      state.loading = false;
      state.errorResponse = response && response.msg || action.payload.msg;
      state.userMessage = '';
      state.usersRegisterData = action.payload;
    },
    getUserByIdRequest(state, action: PayloadAction<any>) { 
      state.loading = true;
      state.errorResponse = null;
      state.userId = action.payload;
    },
    getUserByIdSuccess(state, action: PayloadAction<any>) { 
      const response = action?.payload?.data?.data && deserializedSingleUserResonse(action.payload.data.data);
      state.singleUserData = response.admineditinfo || action.payload.adminEditInfo;
      state.loading = false;
      
    },
    getUserByIdFailure(state, action: PayloadAction<any>) {
      const response = action?.payload?.data?.data && deserializedSingleUserResonse(action.payload.data.data);
      state.loading = false;
      state.userMessage = '';
      state.errorResponse = response && response.msg || action.payload.msg;
      state.singleUserData = action.payload;
    },
    updateUserRequest(state, action) {  
      state.loading = true;
      state.error = null;
      state.usersRegisterData = action.payload;
      state.userMessage = '';
    },
  },
});

export const { actions: usersActions, reducer, name: sliceKey } = usersSlice;
