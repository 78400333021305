import jwtDecode from 'jwt-decode';
let decoded;
export const token =
  localStorage.getItem('token') !== null ? localStorage.getItem('token') : '';
decoded = token ? jwtDecode(token) : '';
export const userInfoToken = decoded;
export const USER_ROLE =
  decoded !== '' && decoded.roleId ? decoded.roleId : null;
export const userId = decoded !== '' && decoded.userId ? decoded.userId : '';
export const superadminRoute = '/admin/dashboard';
export const CUSTOMER_URL =process.env.REACT_APP_SERVER==='huawei'?"https://huawei-test.aliyahabazzar.com/": process.env.REACT_APP_SERVER==='prod'?'https://aliyahabazzar.com/':  process.env.REACT_APP_SERVER === 'stage'
                            ? 'https://ehub-customer.bitsbeat.com/'
                            : 'https://ehub.bitsbeat.com/'
export const DOCUMENT_URL =
  'https://ehub-bitsbeat.s3.ap-south-1.amazonaws.com/';
  // return 'https://ehub-prod.bitsbeat.com/api/customer/v1/';

// export const BASE_URL = 'http://54.151.205.27:3000/api/admin/v1/';
// export const BASE_URL = 'https://6e18ba11bd57.ngrok.io/api/admin/v1/';
// export const BASE_URL = 'http://b03dd525da9e.ngrok.io/api/admin/v1/';
//
// export const BASE_URL = 'https://ehubdev.bitsbeat.com/api/admin/v1/'; //for dev
// export const BASE_URL = 'https://ehub-stage.bitsbeat.com/api/admin/v1/'; //for stage
