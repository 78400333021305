/* eslint-disable */

import React,{useEffect,useState} from 'react';
import { Route, Redirect } from 'react-router-dom';
import { LayoutManagement } from '../app/containers/Dashboard/layoutManagement';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { userinfoSaga } from '../app/containers/Dashboard/saga';
import { sliceKey, reducer, actions } from '../app/containers/Dashboard/slice';
import { selectUploadedError,selectRequesting, selectUploadedResponse,selectPrivilegeResponse } from '../app/containers/Dashboard/selectors';
import {
  MainContent,
  MainWrapper,
} from '../app/components/BaseElements/BaseElements';
import DashboardContext from '../app/containers/Dashboard/DashboardContext';
import { UnAuthorizedPage } from '../app/components/UnauthorizedPage/Loadable';

import jwtDecode from 'jwt-decode';
import { useSelector, useDispatch } from 'react-redux';

const SuperAdminRoute = ({ component: Component, path: path, ...rest }) => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: userinfoSaga });

  const isLoading = useSelector(selectRequesting)
  const uploadedResponse=useSelector(selectUploadedResponse)
  const uploadError=useSelector(selectUploadedError)
	const assignPrivilege=useSelector(selectPrivilegeResponse)

	const dispatch = useDispatch();

  let authenticatedUserRole;
  const token = localStorage.getItem('token');

  if(window.location.pathname.substring(0, 31) !== '/admin/dashboard/product/create'){
    localStorage.getItem('basicInfo') !== null && localStorage.removeItem('basicInfo')
    localStorage.getItem('pricing') !== null && localStorage.removeItem('pricing')
    localStorage.getItem('inventory') !== null && localStorage.removeItem('inventory')
    localStorage.getItem('attributes') !== null && localStorage.removeItem('attributes')
    localStorage.getItem('seo') !== null && localStorage.removeItem('seo')
    localStorage.getItem('updateInfo') !== null &&localStorage.removeItem('updateInfo')
    localStorage.getItem('customerOption') !== null &&localStorage.removeItem('customerOption')
  }

  if (localStorage.getItem('token')) {
    // const { user, exp } = JSON.parse(
    //     atob(localStorage.getItem('token').split('.')[1]),
    //   );
    const decodedToken = jwtDecode(token);
    if (
      typeof decodedToken === 'object' &&
      decodedToken.hasOwnProperty('userId') &&
      decodedToken.hasOwnProperty('exp') &&
      Date.now(decodedToken.exp) > Date.now() / 1000
    ) {
      authenticatedUserRole = true;
    } else {
      authenticatedUserRole = false;
    }
  }
const [availableUuid, setavailableUuid] = useState([])
  useEffect(() => {
   if(Object.keys(assignPrivilege).length>0){
     setavailableUuid(assignPrivilege.dataList.map((x)=>x.uuid))
   }
  }, [assignPrivilege])

  return (
    <>
      {localStorage.getItem('token') !== null? (
        <Route
          render={props =>
            // authenticated === true superadmin
            authenticatedUserRole&&rest.passRoute? (
              <>
                <MainWrapper>
                  <LayoutManagement />
                  <MainContent>
                  <DashboardContext.Provider value={{
                isLoading:isLoading,
                uploadError:uploadError,
                uploadedResponse:uploadedResponse,
                clearUploadImage:()=>dispatch(actions.clearUploadedImage()),
                uploadImage:(payload)=>dispatch(actions.uploadImageRequest(payload)),
              }}>
                    <Component {...props} />
                    </DashboardContext.Provider>
                  </MainContent>
                </MainWrapper>
              </>
            ) : (
              <Route component={UnAuthorizedPage} />
            )
          }
          {...rest}
        />
      ) : (
        <Redirect to="/" />
      )}
    </>
  );
};

export default SuperAdminRoute;
