import styled from 'styled-components';

export const Card =styled.section`

background:#fff;
  box-shadow:0 3px 16px 0 rgba(0, 0, 0, 0.1);
  padding:20px;
  @media screen and (max-width:768px){
    padding:15px;
  } 
  &.bordered{
    box-shadow:none;
    border:1px solid #e6e6e6;
    margin-bottom:20px;
  }
`