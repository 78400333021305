/* import React from 'react';
import Loader from 'app/assets/loader.svg';

const divStyle = {
  margin: '100px auto 100px',
  display: 'block',
};

const Spinner = () => {
  return (
    <div className="spinner">
      <img src={Loader} alt="loader" style={divStyle} />
    </div>
  );
};
export default Spinner;
 */

import styled, { keyframes } from 'styled-components'

const spin = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`

const Spinner = styled.div`
  display: inline-block;
    width: 1rem;
    height: 1rem;
    vertical-align: text-bottom;
    background-color: #fff!important;
    border-radius: 50%;
    opacity: 0;
    animation: spinner-grow .75s linear infinite;
`
export default Spinner;


export const InitialSpinner=styled.div`
display: inline-block;
width: 2rem;
height: 2rem;
vertical-align: text-bottom;
background-color: #007bff!important;
border-radius: 50%;
opacity: 0;
animation: spinner-grow .75s linear infinite;
`