import { put, takeLatest, fork, take, select } from 'redux-saga/effects';
import { customerManagementActions as actions } from './slice';
// import { serializeRegiserUserData } from './serialize';
import { selectCustomerId } from './selectors';
import API from 'utils/apiHelper';
import {
  customerListTable,
  customerViewDetail,
  deleteCustomer,
} from 'Constants/uris';
import {queryFunction} from 'utils/apiQueryHelper';

export function* loadAllCustomerRequest(action) {
  try {
    const token = localStorage.getItem('token');
    const {
      currentpage,
      perpage,
      customerName,
      customerEmail,
      customerPhone,
      customerStatus,
      date,
      orderField,
      orderType,
    } = action.payload;
    
    const querDate = date !== null ? date : '';
    const status =
      customerStatus && customerStatus.value === 'Active'
        ? '1'
        : customerStatus.value === 'Inactive'
        ? '0'
        : '';
        const query={
          name:customerName,
          email:customerEmail,
          phone:customerPhone,
          status,
          orderField,
          orderType,
          currentpage,
          perpage,
        }
    const apiResponse: any = yield fork(
      API.get(
        // `${customerListTable}?filter[name]=${customerName}&filter[email]=${customerEmail}&filter[phone]=${customerPhone}&filter[status]=${status}&currentpage=${page}&perpage=${perpage}&filter[createddate]=${querDate}&orderby[${orderField}]=${orderType}`,
        `${customerListTable}${queryFunction(query,[],[],'orderby')}`,
        actions.loadAllCustomerSuccess,
        actions.loadAllCustomerFailure,
        token,
        'proto',
        'GET',
      ),
    );
    if (apiResponse) {
      yield take(actions.loadAllCustomerSuccess);
    }
  } catch (err) {
    yield put(actions.loadAllCustomerFailure(err));
  }
}

export function* loadSingleCustomerRequest(action) {
  try {
    const token = localStorage.getItem('token');
    const id: any = yield select(selectCustomerId);
    const apiResponse: any = yield fork(
      API.get(
        `${customerViewDetail}?uuid=${id}`,
        actions.getCustomerDetailByIdSuccess,
        actions.getCustomerDetailByIdFailure,
        token,
        'proto',
        'GET',
      ),
    );
    if (apiResponse) {
      yield take(actions.getCustomerDetailByIdSuccess);
    }
  } catch (err) {
    yield put(actions.getCustomerDetailByIdFailure(err));
  }
}

export function* deleteCustomerRequest(action) {
  try {
    const token = localStorage.getItem('token');
    const userId = action.payload;
    const apiResponse: any = yield fork(
      API.delete(
        `${deleteCustomer}?uuid=${userId}`,
        actions.deleteCustomerSuccess,
        actions.deleteCustomerFailure,
        token,
        'proto',
        'delete',
      ),
    );
    if (apiResponse) {
      yield take(actions.deleteCustomerSuccess);
    }
  } catch (err) {
    yield put(actions.deleteCustomerFailure(err));
  }
}

export function* customerManagementSaga() {
  // yield takeLatest(actions.someAction.type, doSomething);
  yield takeLatest(actions.loadAllCustomerRequest.type, loadAllCustomerRequest);
  yield takeLatest(
    actions.getCustomerDetailByIdRequest.type,
    loadSingleCustomerRequest,
  );
  yield takeLatest(actions.deleteCustomerRequest.type, deleteCustomerRequest);
}
