/* eslint-disable */
import AdminRpc from 'proto/build/admin_rpc_pb';

export const deserializedAllAdminResonse = data => {
  try {
    const deserializedData = AdminRpc.ListAdminResponse.deserializeBinary(
      data,
    ).toObject();

    return deserializedData;
  } catch (err) {
    console.log(err, '<<<<<<error');
    throw err;
  }
};

export const deserializedSingleUserResonse = data => {
  try {
    const deserializedData = AdminRpc.AdminBaseResponse.deserializeBinary(
      data,
    ).toObject();

    return deserializedData;
  } catch (err) {
    console.log(err, '<<<<<<error');
    throw err;
  }
};

export const deserializedDeleteResonse = data => {
  try {
    const deserializedData = AdminRpc.AdminBaseResponse.deserializeBinary(
      data,
    ).toObject();

    return deserializedData;
  } catch (err) {
    console.log(err, '<<<<<<error');
    throw err;
  }
};
