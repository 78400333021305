const getEnvUrl = () => {
  switch (process.env.REACT_APP_SERVER) {
    case "huawei":
      return `https://admin-huawei-test.aliyahabazzar.com/api/admin/v1/`
    case 'prod':
      return `https://api.admin.procart.com.au/api/admin/v1/`
    case "stage":
      return `https://ehub-stage.bitsbeat.com/api/admin/v1/`
    case "development":
      return `https://ehubdev.bitsbeat.com/api/admin/v1/`
    default:
      return `http://54.151.205.27:3000/api/admin/v1/`
  }
}

export const BASE_URL =getEnvUrl()



/*******************Login apis******************** */

export const login = 'auth/login'; // Method POST

export const logoutApi = 'auth/logout'; //Method POST

export const getAdminDetail = 'view_admin'; //Method GET

export const taxSettings = 'admin-profile/tax'; //Method POST

export const getTaxsetData = 'admin-profile/tax'; //Method GET

export const changePassword = 'change_password'; //POST

export const forgotPasswordStatus = 'forgot-password/status'; //POST

export const forgotPasswordReset = 'forgot-password/reset'; //POST

export const forgotPassword = 'forgot-password'; //POST

export const productCategory = 'product-category'; //GET all and POST

export const productBrand = 'brand-management/add_brand'; //POST

export const updateDeleteProductCategory = 'product-category/info'; //DELETE and UPDATE

export const productMangement = 'product-management'; //GET and POST

export const messageTemplate = 'template-management'; // GET and POST

export const parentMenuList = 'menu-management/drop-down'; //GET

export const menuManagement = 'menu-management'; //POST, GET

export const homepageProduct = 'homepage-product'; // POST, GET

export const menuProduct = 'menu-product'; // POST, GET

/*-------------------------BANNER MANAGMENT-------------------------*/
export const createBanner = 'banner-management/add_banner'; // POST
export const banner = 'banner-management'; // POST

/*--------------------------CONTENT-TEMPLATE---------------------*/
export const contentTemplate = 'content-template'; // POST

/*-----------------------------------------------------------------*/
export const faq = 'faq'; // GET POST DELETE
export const productMenuDropdownList = '/menu-management/product/drop-down'; // GET

export const socialMediaLinks = 'social-media'; // POST, GET

export const brandList = 'brand-management/get_brand_list'; //GET
export const brandListTable = 'brand-management/get_brand_list'; //GET
export const singleBrandInfo = 'brand-management/get_brand_info'; //GET
export const updateProductBrand = 'brand-management/update_brand'; //Put
export const deleteProductBrand = 'brand-management/delete_brand'; //Delete
export const uploadBrandImage = 'bucket/upload'; //miltipart Post
export const customerListTable = 'customer-management/get_customer_list'; //GET
export const customerViewDetail = 'customer-management/view_customer'; //GET
export const deleteCustomer = 'customer-management/delete_customer'; //Delete
export const updateCustomer = 'customer-management/update_customer'; //Put
export const viewAllOrders = 'order-management/view-order-list'; //GET

export const singleOrderInfo = 'order-management/view-order'; //GET
export const updateOrder = 'order-management/edit-order'; //GET

export const orderInvoice = 'order-management/view-invoice'

/* ----------------------------GET-ADMIN-PRIVILEGE------------------- */
export const getPrivilege = 'admin-profile/privilege'

/* ----------------------------SEND MESSAGE------------------------ */
export const notificationMessage = '/message-management/send-msg' //PUT

/* ----------------------------UPLOAD FILE------------------------ */
export const uploadFileApi = 'bucket/upload-file' //PUT

export const privilegeResponseConfig = {
  dashboard: {
    uuid: '2087e7a4-8c66-11eb-9036-6b742c222f10',
  },
  adminUsers: {
    uuid: '39336d82-8c66-11eb-86f7-7750da90620b',
  },
  customers: {
    uuid: '4f052858-8c66-11eb-b9aa-cbd9df0b8132',
  },
  orders: {
    uuid: '65073042-8c66-11eb-9c17-271b167d9822',
  },
  products: {
    uuid: '29cf9fc0-8c78-11eb-911d-770f4c3142e3',
    childList: {
      allProducts: {
        uuid: 'c0e4ac30-886c-11eb-bb4a-87a1037b4b19'
      },
      productBrand: {
        uuid: 'ce061a20-886c-11eb-b785-ef981f2c9b4c'
      },
      productCategory: {
        uuid: 'da1257b6-886c-11eb-b596-d3250018260e'
      },
      homepageProduct: {
        uuid: 'c6b54642-8c68-11eb-8174-f7ecc6733e1c'
      },
      menuProduct: {
        uuid: 'e3feee06-8c68-11eb-971f-9368c3d685a7'
      }
    }
  },
  menu: {
    uuid: 'b3a53b7c-8c66-11eb-a2dd-2bb3cf32909e',
    childList: {
      setupMenu: {
        uuid: 'cadcac4e-8c66-11eb-a346-2f2c4ee2d5c8'
      }
    }
  },
  noitifcationMessage: {
    uuid: 'f5b07b6c-8c66-11eb-9bcb-437f8349fb17',
    childList: {
      pushNotification: {
        uuid: 'f6703042-8c66-11eb-be49-af2c29754ad1'
      },
      sms: {
        uuid: '163b49ac-8c67-11eb-8bd0-6bd80a8a780a'
      },
      email: {
        uuid: '1f1f069e-8c67-11eb-a6e6-0fed5a73fec4'
      }
    }
  },
  settings: {
    uuid: '6358c5e8-8c67-11eb-ba7c-9fa8c21234bf',
    childList: {
      socialMediaLinks: {
        uuid: 'a69d97ca-8c67-11eb-8d52-f78b4d802aa5'
      },
      webBanners: {
        uuid: 'ac8631a6-8c67-11eb-a65c-8ffc6cec245a'
      },
      pageTemplate: {
        uuid: 'b263adc4-8c67-11eb-a858-bb3533767c36'
      },
      customerFaq: {
        uuid: 'ba049840-8c67-11eb-9f06-f38252b7e9b8'
      },
      notificationTemplate: {
        uuid: 'c013db24-8c67-11eb-9b3c-d37b2ba1772d'
      },
      taxSettings: {
        uuid: 'c69a89d4-8c67-11eb-9e6d-6f5d490c7a8a'
      },
      changePassword: {
        uuid: 'cc38a4fc-8c67-11eb-b8cb-1785e068f4c6'
      }
    }
  },
  contactUs: {
    uuid: 'aa9ad0a0-9288-11eb-bf63-9389a6e2d3e7',
  }
}

export const menuUUID = {
  dashboard: privilegeResponseConfig.dashboard.uuid,
  adminUsers: privilegeResponseConfig.adminUsers.uuid,
  customers: privilegeResponseConfig.customers.uuid,
  orders: privilegeResponseConfig.orders.uuid,

  /* PRODUCTS */
  products: privilegeResponseConfig.products.uuid, // PARENT
  allProducts: privilegeResponseConfig.products.childList.allProducts.uuid,
  productBrand: privilegeResponseConfig.products.childList.productBrand.uuid,
  productCategory: privilegeResponseConfig.products.childList.productCategory.uuid,
  homepageProduct: privilegeResponseConfig.products.childList.homepageProduct.uuid,
  menuProduct: privilegeResponseConfig.products.childList.menuProduct.uuid,

  /* MENU */
  menu: privilegeResponseConfig.menu.uuid, // PARENT
  setupMenu: privilegeResponseConfig.menu.childList.setupMenu.uuid,

  /* NOTICIATION MESSAGE */
  noitifcationMessage: privilegeResponseConfig.noitifcationMessage.uuid, //PARENT
  pushNotification: privilegeResponseConfig.noitifcationMessage.childList.pushNotification.uuid,
  sms: privilegeResponseConfig.noitifcationMessage.childList.sms.uuid,
  email: privilegeResponseConfig.noitifcationMessage.childList.email.uuid,

  /* SETTINGS */
  settings: privilegeResponseConfig.settings.uuid, //PARENT
  socialMediaLinks: privilegeResponseConfig.settings.childList.socialMediaLinks.uuid,
  webBanners: privilegeResponseConfig.settings.childList.webBanners.uuid,
  pageTemplate: privilegeResponseConfig.settings.childList.pageTemplate.uuid,
  customerFaq: privilegeResponseConfig.settings.childList.customerFaq.uuid,
  notificationTemplate: privilegeResponseConfig.settings.childList.notificationTemplate.uuid,
  taxSettings: privilegeResponseConfig.settings.childList.taxSettings.uuid,
  changePassword: privilegeResponseConfig.settings.childList.changePassword.uuid,

  /* CONTACT US */
  contactUs: privilegeResponseConfig.contactUs.uuid
}
