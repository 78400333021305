import { put, select, takeLatest, fork, take, delay } from 'redux-saga/effects';
import { usersActions as actions } from './slice';
import { serializeRegiserUserData, serializeEditUserData } from './serialize';
import API from 'utils/apiHelper';
import {queryFunction} from 'utils/apiQueryHelper';
import { selectUserRegisterData } from './selectors';
import { selectUserId } from './selectors';
import history from 'utils/history';
export function* getAllUsersData(action) {
  const searchQuery = {
    ...action.payload,
    currentpage:action.payload.page,
    page:null,
    perpage:action.payload.perPage,
    perPage:null
  };
  try {
    const token = localStorage.getItem('token');
    const apiResponse: any = yield fork(
      API.get(
        // `get_all_admin?currentpage=${searchQuery.page}&perpage=${searchQuery.perPage}&filter[name]=&filter[email]=${searchQuery.email}&filter[role]=&orderby[${searchQuery.orderField}]=${searchQuery.orderType}`,
        `get_all_admin${queryFunction(searchQuery,[],[],'orderby')}`,
        actions.allUsersDataLoaded,
        actions.allUsersDataError,
        token,
        'proto',
        'GET',
      ),
    );
    if (apiResponse) {
      yield take(actions.allUsersDataLoaded);
    }
  } catch (err) {
    yield put(actions.allUsersDataError(err));
  }
}
export function* saveUserRequest() {
  try {
    let serializeData = null;
    const token = localStorage.getItem('token');
    const data: any = yield select(selectUserRegisterData);
    const jsonData = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phone: data.phoneNumber,
      password: data.password,
      type: data.type,
      isActive: data.isActive,
    };
    serializeData = serializeRegiserUserData(jsonData);
    const loginResp: any = yield fork(
      API.post(
        'register_admin',
        actions.saveUserSuccess,
        actions.saveUserFailure,
        serializeData || jsonData,
        token,
        'proto',
        'POST',
      ),
    );
    if (loginResp) {
      yield take(actions.saveUserSuccess);
      history.push('/admin/dashboard/users');
    }
  } catch (err) {
    yield put(actions.saveUserFailure(err));
  }
}
export function* getUserByIdRequest() {
  try {
    const token = localStorage.getItem('token');
    const userId: string = yield select(selectUserId);

    const apiResponse: any = yield fork(
      API.get(
        `get_edit_admin_info?userId=${userId}`,
        actions.getUserByIdSuccess,
        actions.getUserByIdFailure,
        token,
        'proto',
        'GET',
      ),
    );
    if (apiResponse) {
      yield take(actions.getUserByIdSuccess);
    }
  } catch (err) {
    yield put(actions.getUserByIdFailure(err));
  }
}

export function* updateUserRequest(action) {
  try {
    let serializeData = null;
    const token = localStorage.getItem('token');
    const data: any = action.payload.userData;
    const userId = action.payload.id;
    const jsonData = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phone: data.phoneNumber,
      password: data.password,
      userId: userId,
      type: data.type,
      isActive: data.isActive,
    };
    serializeData = serializeEditUserData(jsonData);

    const loginResp: any = yield fork(
      API.put(
        'edit_admin',
        actions.saveUserSuccess,
        actions.saveUserFailure,
        serializeData,
        token,
        'proto',
        'put',
      ),
    );
    if (loginResp) {
      yield take(actions.saveUserSuccess);
      history.push('/admin/dashboard/users');
    }
  } catch (err) {
    yield put(actions.saveUserFailure(err));
  }
}
export function* deleteUserRequest(action) {
  try {
    const token = localStorage.getItem('token');
    const userId = action.payload;
    // const loginResp: any =
    
    yield fork(
      API.delete(
        `delete_admin?userId=${userId}`,
        actions.deleteUserSuccess,
        actions.deleteUserFailure,
        token,
        'proto',
        'delete',
      ),
    );
    
    // if (loginResp) {
    //   yield delay(5000);
    //   window.location.reload(false);
    // }
  } catch (err) {
    yield put(actions.deleteUserFailure(err));
  }
}

export function* usersSaga() {
  // yield takeLatest(actions.someAction.type, doSomething);
  yield takeLatest(actions.loadAllUsersData.type, getAllUsersData);
  yield takeLatest(actions.saveUserRequest.type, saveUserRequest);
  yield takeLatest(actions.getUserByIdRequest.type, getUserByIdRequest);
  yield takeLatest(actions.updateUserRequest.type, updateUserRequest);
  yield takeLatest(actions.deleteUserRequest.type, deleteUserRequest);
}
