import React from 'react';
import styled from 'styled-components';

import {theme} from '../BaseElements/Theme';
import { FiMenu} from 'react-icons/fi';
import Logo from '../../assets/image/mainlogo.png'


const NavigationWrapper = styled.div`
 display:flex; 
 justify-content: space-between;
 align-items:center;
 padding:13px 20px;
 z-index:999;
 position:fixed;
 width:100%;
 background:${theme.mainColor}

`
const MenuButton = styled.button`
 padding:2px 5px;
  color:#fff;
  border:1px solid rgba(255,255,255,0.3);
  border-radius:2px;
  background:transparent;
  text-align:center;
  font-size:24px;
  line-height:24px;
  display:none;
  margin-right:10px;
  &:hover{
    background:rgba(255,255,255,0.2);
  }

  @media screen and (max-width:768px){
    display:inline-block;
  }
`
const SearchWrapper =styled.div`
margin:0 10px;
display:flex;
`

// const Search = styled.input`
// padding: 0.1em 1em;
//   border-radius: 4px;
//   border: none;
//   background:rgba(255,255,255,0.2);
//   border-top-right-radius:0px;
//   border-bottom-right-radius:0px;
//  height:34px;
//   color:#fff;
//   &::placeholder{
//     color:rgba(255,255,255,0.8);
//   }

// `

// const SearchButton = styled.button `
//    border:none;
//    background:rgba(255,255,255,0.2);
//    color:#818a91;
//    font-size:16px;
//    padding: 0em 0.5em;
//    border-top-right-radius:4px;
//    border-bottom-right-radius:4px;
//    border: none;
//    border-left:0px;
//    color:rgba(255,255,255,0.8);
//    line-height:21px;
//    &:hover, &:focus{
//      color:rgba(255,255,255,1);
//    }
// `

// const ExitButton = styled.button `
// border:none;
//    background:rgba(255,255,255,0.2);
//    color:#fff;
//    padding: 0.2em 0.5em;
//    border-radius:4px;
//    margin-left:5px;

//    &:hover{
//      background:#fff;
//      color:${theme.mainColor};
//    }
// ` 

const LogoImg = styled.img`
   height:40px;
   filter: brightness(0) invert(1);
   margin-left: 5px;
   @media screen and (max-width:768px){
     height:26px;
   }
` 

interface Props {
  onClickToggleButton?: any
}

const TopNavigation: React.FC<Props> = (props) => {
    return (
      <NavigationWrapper>
        <div>
          <MenuButton onClick={props.onClickToggleButton}>
              <FiMenu/>
          </MenuButton>
          <LogoImg src={Logo} alt="aliyaha logo"/>
        </div>
        
        <div>
          <SearchWrapper>
            {/* <Search placeholder="Search"></Search>
            <SearchButton>
             <FiSearch/>
            </SearchButton> */}
            {/* <ExitButton>
              <FiLogOut/>
            </ExitButton> */}
          </SearchWrapper>
        </div>
      
      </NavigationWrapper>
    );
};

export default TopNavigation;


