/**
 *
 * Asynchronously loads the component for MessageManagement
 *
 */

import { lazyLoad } from 'utils/loadable';

export const CreatEmailMessage = lazyLoad(
  () => import('./index'),
  module => module.CreatEmailMessage,
);
