
// theme
export const theme = {
  // mainColor: "#2680EB",
  mainColor: "#005ac5",
  semanticGreen:"#5CB85C",
  semanticYellow:"#F0AD4E",
  semanticRed:"#CB2E25",
  semanticBlue:"#56C0E0",
  textColor:"#373A3C",
  dimGrey:"#dddddd"
};