



import React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {superadminRoute} from '../../../Constants/Constants';

const BreadcrumbWrapper = styled.ul`
margin:0;
padding:0;
`;
const BreadcrumbItem = styled.li`
font-size:16px;
margin-right:12px;
position:relative;
list-style-type:none;
display:inline-block;
  &:after{
    content:'|';
    position:absolute;
    right:-10px;
  }
  &:last-child{
    &:after{
      display:none;
    }
  }
 @media screen and (max-width:768px){
   font-size:14px;
 } 
 @media screen and (max-width:475px){
  font-size:13px;
} 
`
// const BreadcrumbLink = styled.a`
//   color:${theme.mainColor};
//   text-transform:lowercase;
// `
interface Props{
  breadCrumb?: any;
  method?: any;
  link?: any;
}

// function Breadcrumb({breadCrumb}: Props) {
  const Breadcrumb: React.FunctionComponent<Props> = props => {
    
    return (
      <BreadcrumbWrapper>
        <BreadcrumbItem >
        {props.breadCrumb === "" ? (
          <>Admin Dashboard</>
        )
          : <Link to={`${superadminRoute}`}>Admin Dashboard</Link>
        }
        </BreadcrumbItem>

        {props.link !== "" ? (
         <> <Link to={`${superadminRoute}${props.link}`}> {props.breadCrumb} </Link> |</>
        ): (
          <BreadcrumbItem >
          {props.breadCrumb}
           </BreadcrumbItem>
        )}
       
        {props.method && (
        <BreadcrumbItem >
        {props.method}
        </BreadcrumbItem>

)}
        
      </BreadcrumbWrapper>
    );
};

export default Breadcrumb;







