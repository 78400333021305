/**
 *
 * Asynchronously loads the component for ContentTemplate
 *
 */

import { lazyLoad } from 'utils/loadable';

export const CreateContentTemplate = lazyLoad(
  () => import('./index'),
  module => module.CreateContentTemplate,
);
