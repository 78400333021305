/**
 *
 * OrderManagement
 *
 */

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components/macro';

import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { reducer, sliceKey } from './slice';
// import { selectOrderManagement } from './selectors';
import { orderManagementSaga } from './saga';

interface Props {}

export default function ViewOrderInvoice(props: Props) {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: orderManagementSaga });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const orderManagement = useSelector(selectOrderManagement);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const dispatch = useDispatch();

  return (
    <>
      <Div>Order Invoice</Div>
    </>
  );
}

const Div = styled.div``;
