/*
 * Login Slice
 *
 * Here we define:
 * - The shape of our container's slice of Redux store,
 * - All the actions which can be triggered for this slice, including their effects on the store.
 *
 * Note that, while we are using dot notation in our reducer, we are not actually mutating the state
 * manually. Under the hood, we use immer to apply these updates to a new copy of the state.
 * Please see https://immerjs.github.io/immer/docs/introduction for more information.
 *
 */

import { createSlice } from 'utils/@reduxjs/toolkit';
import { ContainerState } from './types';
import { deserializeUserResonse, deserializedLogOutResonse,deserializedPrivilegeResonse ,deserializeCommonResponse} from './deserialize';
import history from 'utils/history';

// The initial state of the Blogs container
export const initialState: ContainerState = {
  requesting: false,
  success: false,
  error: null,
  userInfo: {},
  userID: {},
  logoutRequest: false,
  logoutSuccess: false,
  logoutSuccessMessage: '',
  uploadedResponse: [],
  uploadError: '',
  privilegeResponse:{},
  isPrivilegeRequesting:false
  // isPrivilegeRequesting:false
};

const userInfoSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    fetchUserDetailRequest(state, action: any) {
      state.requesting = true;
      state.error = null;
      state.userInfo = {};
      state.userID = action.payload;
      state.success = false;
    },

    fetchUserDetailSuccess(state, action: any) {
      const deserializedResp = action?.payload?.data?.data && deserializeUserResonse(action.payload.adminDetail)
      state.userInfo = deserializedResp || action?.payload?.adminDetail;
      state.requesting = false;
      state.error = null;
      state.success = true;
    },

    fetchUserDetailFailure(state, action: any) {
      const deserializedResp = action?.payload?.data?.data && deserializeUserResonse(
        action.payload.data.data,
      );
      const responseData = deserializedResp?.msg;

      state.userInfo = {};
      state.error = responseData || action?.payload?.msg;
      state.requesting = false;
      state.success = false;
    },
    logoutRequest(state) {
      state.logoutRequest = true;
      state.error = null;
      state.logoutSuccessMessage = '';
      state.logoutSuccess = false;
    },
    logoutSuccess(state, action: any) {
      const deserializedResp = action?.payload?.data?.data && deserializedLogOutResonse(action.payload.data.data)
      const responseData = deserializedResp?.msg;
      localStorage.removeItem('token');

      state.logoutSuccessMessage = responseData;
      state.logoutRequest = false;
      state.error = null;
      state.userInfo = {};
      state.logoutSuccess = true;
    },
    logoutFailure(state, action: any) {
      const deserializedResp = action?.payload?.data?.data && deserializedLogOutResonse(action.payload.data.data)

      state.logoutSuccessMessage = '';
      state.error = deserializedResp?.msg;
      state.logoutRequest = false;
      state.logoutSuccess = false;
    },

    uploadImageRequest(state) {
      state.uploadError = '';
      state.requesting = true;
      state.uploadedResponse = [];
    },
    uploadImageSuccess(state, action: any) {
      const response = action?.payload
      state.uploadedResponse = response?.file_location[0]
      state.requesting = false;
      state.uploadError = '';
    },
    uploadImageFailure(state, action: any) {
      state.uploadError = action.payload.message;
      state.requesting = false;
      state.uploadedResponse = []
    },

    getAdminPrivilegeRequest(state) {
      state.isPrivilegeRequesting = true;
      state.error=null;
      state.privilegeResponse = {};
    },
    getAdminPrivilegeSuccess(state, action: any) {
      const response = action?.payload?.data?.data&&deserializedPrivilegeResonse(action.payload.data.data)||action.payload;
      state.privilegeResponse = response;
      state.isPrivilegeRequesting = false;
    },
    getAdminPrivilegeFailure(state, action: any) {
      localStorage.clear();
      history.push('/')
      const response=action?.payload?.data?.data&& deserializeCommonResponse(action.payload.data.data)||action.paylaod
      state.error = response?.msg||response?.message;
      state.isPrivilegeRequesting = false;
      state.privilegeResponse = {}
    },

    clearState(state) {
      state.requesting = false;
      state.error = null;
      state.userInfo = {};
      state.userID = {};
      state.success = false;
      state.privilegeResponse={};
      state.logoutRequest = false;
      state.logoutSuccess = false;
      state.logoutSuccessMessage = '';
      state.isPrivilegeRequesting=false;
    },
    clearUploadedImage(state) {
      state.uploadedResponse = []
    }
  },
});

export const { actions, reducer, name: sliceKey } = userInfoSlice;
