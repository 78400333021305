/* eslint-disable */
import MesageTemplate from 'proto/build/messaging_pb';
import TemplateById from 'proto/build/messaging_pb';

export const deserializeMessageTemplate = data => {
  try {
    const deserializedData = MesageTemplate.GetTemplateListResponse.deserializeBinary(
      data,
    ).toObject();
    return deserializedData;
  } catch (err) {
    console.log(err, '<<<<<<error');
    throw err;
  }
};

export const deserializeTemplateById = data => {
  try {
    const deserializedData = TemplateById.GetTemplateDetailResponse.deserializeBinary(
      data,
    ).toObject();
    return deserializedData;
  } catch (err) {
    console.log(err, '<<<<<<error');
    throw err;
  }
};