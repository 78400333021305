export function trimSpace(data: string) {
    const wsRegex = /^\s+|\s+$/g; 
    return typeof data === "string" ? data.replace(wsRegex, "") : data;
  }
  

export const queryFunction = (data, exclude = [], assignWithoutFilter = [],order='order') => {
    const defaultExlcude = [...exclude, "search", "orderField", "api", "orderType"];
    let filterQuery = "";
    const removeFilterString = [
      ...assignWithoutFilter,
      "currentpage",
      "perpage",
      "page",
      'perPage',
      "api"
    ];
    if (data) {
      Object.keys(data).map(
        // eslint-disable-next-line no-return-assign
        x => {
          if (data[x] && !defaultExlcude.includes(x)) {
            filterQuery += `${filterQuery.includes("?") ? "&" : "?"}${
              removeFilterString.includes(x)
                ? `${x}=${trimSpace(data[x])}`
                : typeof(data[x]) === "object"
                ? `filter[${x}]=${trimSpace(data[x]?.key)}`
                : `filter[${x}]=${trimSpace(data[x])}`
            }`;
          }
          if (x === "orderField" && data[x]) {
            filterQuery += `${filterQuery.includes("?") ? "&" : "?"}${order}[${
              data[x]
            }]=${data['orderType'] === "asc" ? "asc" : "desc"}`;
          }
  
          return null;
        }
      );
    }
    return filterQuery;
  };