import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from './slice';

const selectDomain = (state: RootState) =>
  state.customerManagement || initialState;

export const selectCustomerManagement = createSelector(
  [selectDomain],
  customerManagementState => customerManagementState,
);

export const selectLoading = createSelector(
  [selectDomain],
  state => state.loading,
);

export const selectError = createSelector(
  [selectDomain],
  state => state.error,
);

export const selectServerMessage = createSelector(
  [selectDomain],
  state => state.serverMessages,
);

export const selectAllCustomers = createSelector(
  [selectDomain],
  state => state.allCustomers,
);

export const singleCustomerData = createSelector(
  [selectDomain],
  state => state.allCustomers,
);

export const selectCustomerId = createSelector(
  [selectDomain],
  state => state.customerId,
);

export const selectSingleCustomerDetails = createSelector(
  [selectDomain],
  state => state.singleData,
);
export const selectSingleCustomerActivity = createSelector(
  [selectDomain],
  state => state.singleCustomerActivity,
);
