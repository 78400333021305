import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from './slice';

// First select the relevant part from the state
const selectDomain = (state: RootState) => state.global || initialState;

export const selectRequesting = createSelector(
  [selectDomain],
  state => state.requesting,
);

export const selectSuccess = createSelector(
  [selectDomain],
  state => state.success,
);

export const selectError = createSelector(
  [selectDomain],
  state => state.error,
);

export const selectUserInfo = createSelector(
  [selectDomain],
  state => state.userInfo,
);

export const selectUserId = createSelector(
  [selectDomain],
  state => state.userID,
);
  export const selectLogoutRequest = createSelector(
    [selectDomain],
    state => state.logoutRequest,
  );
    export const selectLogoutSuccessMessage = createSelector(
      [selectDomain],
      state => state.logoutSuccessMessage,
);

export const selectUploadedResponse = createSelector(
  [selectDomain],
  state => state.uploadedResponse,
);

export const selectUploadedError = createSelector(
  [selectDomain],
  state => state.uploadError,
);

export const selectPrivilegeResponse = createSelector(
  [selectDomain],
  state => state.privilegeResponse,
);

export const selectisPrivilegeRequesting = createSelector(
  [selectDomain],
  state => state.isPrivilegeRequesting,
);