import { put, takeLatest, fork, take } from 'redux-saga/effects';
import { templateActions as actions } from './slice';
import API from 'utils/apiHelper';
import {
  messageTemplate,
} from 'Constants/uris';
import { queryFunction } from 'utils/apiQueryHelper';

export function* loadMessageTemplateRequest(action) {
  try {
    const token = localStorage.getItem('token');
    const {
      page,
      perpage,
      notificationstatus,
      notificationType,
      description,
      orderField,
      orderType,
    } = action.payload;
    const query = {
      status: notificationstatus.value || '',
      type: notificationType.value || '',
      description,
      orderField,
      orderType,
      currentpage:page,
      perpage:perpage
    }

    const apiResponse: any = yield fork(
      API.get(
        `${messageTemplate}${queryFunction(query,[],[],'orderby')}`,
        // currentpage=${page}&perpage=${perpage}&filter[status]=${notificationstatus?.value ? notificationstatus?.value:''}&filter[type]=${notificationType?.value ? notificationType?.value:''}&filter[description]=${description}&orderby[${orderField}]=${orderType}
        actions.loadMessageTemplateSuccess,
        actions.loadMessageTemplateFailure,
        token,
        'proto',
        'GET',
      ),
    );
    if (apiResponse) {
      yield take(actions.loadMessageTemplateSuccess);
    }
  } catch (err) {
    yield put(actions.loadMessageTemplateFailure(err));
  }
}

export function* getTemplateByIdRequest(action) {
  try {
    const token = localStorage.getItem('token');
    const id: any = action.payload
    const apiResponse: any = yield fork(
      API.get(
        `${messageTemplate}/info?eventName=${id}`,
        actions.getTemplateByIdSuccess,
        actions.getTemplateByIdFailure,
        token,
        'proto',
        'GET',
      ),
    );
    if (apiResponse) {
      yield take(actions.getTemplateByIdSuccess);
    }
  } catch (err) {
    yield put(actions.getTemplateByIdFailure(err));
  }
}

export function* messageTemplateSaga() {
  yield takeLatest(actions.loadMessageTemplateRequest.type, loadMessageTemplateRequest);
  yield takeLatest(
    actions.getTemplateByIdRequest.type,
    getTemplateByIdRequest,
  );
}
