import React, { useEffect, useState } from 'react';
import jwtDecode from 'jwt-decode';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { userinfoSaga } from './saga';
import { sliceKey, reducer, actions } from './slice';
import SideNavigation from './components/SideNavigation';
import TopNavigation from 'app/components/TopNavigation';
import CustomToaster from 'app/components/Toaster';

import { selectLogoutSuccessMessage, selectUserInfo,selectPrivilegeResponse,selectisPrivilegeRequesting } from './selectors';
import { useSelector, useDispatch } from 'react-redux';
import history from '../../../utils/history';

export function LayoutManagement() {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: userinfoSaga });

  const [showMenu, setShowMenu] = useState<any>(false);

  const logoutSuccessMessage = useSelector(selectLogoutSuccessMessage);
  const userInfo = useSelector(selectUserInfo);
  const assignPrivilege=useSelector(selectPrivilegeResponse)
  const isRequesting=useSelector(selectisPrivilegeRequesting)
  const dispatch = useDispatch();

  useEffect(() => {
    const token: any = localStorage.getItem('token');
    let decoded: any = token ? jwtDecode(token) : '';
    const userId = decoded !== '' && decoded.userId ? decoded.userId : '';
    // dispatch(actions.getAdminPrivilegeRequest())
    if (userId !== ''&&Object.keys(userInfo).length===0) {
      dispatch(actions.fetchUserDetailRequest(userId));
    }
  }, []);

  const handleLogout = () => {
    
    dispatch(actions.logoutRequest());
  };

  useEffect(() => {
    if (!localStorage.token) {
      history.push('/');
    }
  }, [logoutSuccessMessage]);

  const handleToggleButton = () => {
    setShowMenu(!showMenu);
  };
  return (
    <>
      <TopNavigation onClickToggleButton={handleToggleButton} />
      <SideNavigation
      assignPrivilege={assignPrivilege}
      handleLogout={handleLogout}
      userInfo={userInfo}
      showMenu={showMenu}
      isRequesting={isRequesting}
      onClickToggleButton={handleToggleButton}
      />
      <CustomToaster />
    </>
  );
}
