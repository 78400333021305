/**
 * Asynchronously loads the component for ResetPasswordFlow
 */

import { lazyLoad } from 'utils/loadable';

export const TaxSetting = lazyLoad(
  () => import('./index'),
  module => module.TaxSetting,
);
