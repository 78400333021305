import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { MessageTemplateState } from './types';
import { deserializeMessageTemplate, deserializeTemplateById } from './deserialize';

// The initial state of the Message Template container
export const initialState: MessageTemplateState = {
  loading: false,
  error: null,
  serverMessages:'',
  messageId: "",
  query: {},
  detailInfo:{},
  allMessageTemplates: {},
};

const MessageTemplateSlice = createSlice({
  name: 'messageTemplate',
  initialState,
  reducers: {
    loadMessageTemplateRequest(state, action: PayloadAction<any>) { 
      state.loading = true;
      state.error = null;
      // state.serverMessages ='';
      state.query = action.payload;
    },

    loadMessageTemplateSuccess(state, action: PayloadAction<any>) {
      const response = action?.payload?.data?.data && deserializeMessageTemplate(action.payload.data.data);
      
      const data = response?.dataList || action.payload.dataList;
      const paginate= response?.pagination || action.payload.pagination;
      state.allMessageTemplates = {data,paginate};
      state.loading = false;
      state.error = null;
    },

    loadMessageTemplateFailure(state, action: PayloadAction<any>) {
      const response = action?.payload?.data?.data && deserializeMessageTemplate(action.payload.data.data);
      state.error =action.payload.status!==404&& (response?.msg || action.payload.msg);
      state.allMessageTemplates = {data: [],paginate: {}};
      state.loading = false;
      state.allMessageTemplates = {};
    },

    getTemplateByIdRequest(state, action: PayloadAction<any>) { 
      state.error = null;
      state.loading = true;
    },

    getTemplateByIdSuccess(state, action: PayloadAction<any>) {
      const response = action?.payload?.data?.data && deserializeTemplateById(action.payload.data.data);
      
      state.detailInfo = response?.data || action.payload.data;
      state.loading = false;
      state.error = null;
    },

    getTemplateByIdFailure(state, action: PayloadAction<any>) { 
      const response = action?.payload?.data?.data && deserializeTemplateById(action.payload.data.data);
      state.error = response.msg || action?.payload?.msg;
      state.serverMessages = '';
      state.loading = false;
    },

    clearStateMessages(state){ 
      state.serverMessages='';
      state.error= null;
    },
  },
});

export const { actions: templateActions, reducer, name: sliceKey } = MessageTemplateSlice;
