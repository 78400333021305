/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React, { Suspense, lazy, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Switch, Route, Redirect, Router, BrowserRouter } from 'react-router-dom';
import { superadminRoute } from 'Constants/Constants';
import AdminRoute from 'utils/SuperAdminRoute';
import Spinner from 'app/components/Spinner';

import { GlobalStyle } from 'styles/global-styles';
import { Blog } from './containers/Blogs/Loadable';
import { Login } from './containers/Login/Loadable';
import { ChangePassword } from './components/AdminProfile/Password/Loadable';
import { NotFoundPage } from './components/NotFoundPage/Loadable';
import { ResetPasswordFlow } from './containers/Login/PasswordReset/Loadable';
import { TaxSetting } from './containers/Dashboard/containers/Settings/TaxSettings/Loadable';
import { Users } from './containers/Dashboard/containers/Users/Loadable';
import { ProductModule } from './containers/Dashboard/containers/ProductManagement/Loadable';
import { ProductCategoryModule } from './containers/Dashboard/containers/ProductCategory/Loadable';
import { CreateNewCatgory } from './containers/Dashboard/containers/ProductCategory/CreateNewCategory/Loadable';
import { ProductBrandModule } from './containers/Dashboard/containers/ProductBrand/Loadable';
import { CreateNewBrand } from './containers/Dashboard/containers/ProductBrand/CreateNewBrand/Loadable';
import { CreateProduct } from './containers/Dashboard/containers/ProductManagement/CreateProduct/Loadable';
import CreateUserForm from './containers/Dashboard/containers/Users/CreateUserForm';
import ViewUserDetail from './containers/Dashboard/containers/Users/ViewDetail';
import { CustomerManagement } from './containers/Dashboard/containers/CustomerManagement/Loadable';
import { CreateNewCustomer } from './containers/Dashboard/containers/CustomerManagement/CreateNewCustomer/Loadable';
import { MessageTemplateModule } from './containers/Dashboard/containers/Settings/MessagingTemplate/Loadable';
import { EditTemplate } from './containers/Dashboard/containers/Settings/MessagingTemplate/UpdateTemplate/Loadable';
import ViewCustomerDetail from './containers/Dashboard/containers/CustomerManagement/ViewDetail';
import ViewMessageTemplateDetail from './containers/Dashboard/containers/Settings/MessagingTemplate/ViewDetail';
import { MenuModule } from './containers/Dashboard/containers/MenuManagement/Loadable';
import { CreateMenu } from './containers/Dashboard/containers/MenuManagement/CreateMenu/Loadable';
import { HomapageProductModule } from './containers/Dashboard/containers/MenuManagement/HomePageProductManagement/Loadable';
import { MenuProductManagementModule } from './containers/Dashboard/containers/MenuManagement/MenuProductManagement/Loadable';
/* --------------------------------BANNER MODULE-------------------------------- */
import { CreateBannerModule } from './containers/Dashboard/containers/Banner/CreateNewBanner/Loadable';
import { BannerModule } from './containers/Dashboard/containers/Banner/Loadable';
import { ViewDetails } from './containers/Dashboard/containers/Banner/ViewDetails/Loadable';

/* --------------------------------CONTENT TEMPLATE------------------------------------------ */
import { ContentTemplate } from './containers/Dashboard/containers/ContentTemplate/Loadable';
import { CreateContentTemplate } from './containers/Dashboard/containers/ContentTemplate/CreateTemplate/Loadable';
/* --------------------------------FAQ MODULE------------------------------------------ */
import { FaqGetModule } from './containers/Dashboard/containers/Faq/Loadable';
import { CreateFaqModule } from './containers/Dashboard/containers/Faq/CreateFaq/Loadable';
import { ViewFaqDetails } from './containers/Dashboard/containers/Faq/ViewDetails/Loadable';
/* --------------------------------MESSAGE MANAGEMENT----------------------------------- */
import { PushMessage } from './containers/Dashboard/containers/MessageManagement/PushMessage/Loadable';
import { CreatPushMessage } from './containers/Dashboard/containers/MessageManagement/PushMessage/CreatePushMessage/Loadable';
import { ViewPushMessageDetails } from './containers/Dashboard/containers/MessageManagement/PushMessage/ViewDetails/Loadable';

import { EmailMessage } from './containers/Dashboard/containers/MessageManagement/EmailMessage/Loadable';
import { ViewEmailMessageDetails } from './containers/Dashboard/containers/MessageManagement/EmailMessage/ViewDetails/Loadable';
import { CreatEmailMessage } from './containers/Dashboard/containers/MessageManagement/EmailMessage/CreateEmailMessage/Loadable';

import { CreatSmsMessage } from './containers/Dashboard/containers/MessageManagement/SmsMessage/CreateSmsMessage/Loadable';
import { ViewSMSMessageDetails } from './containers/Dashboard/containers/MessageManagement/SmsMessage/ViewDetails/Loadable';
import { SmsMessage } from './containers/Dashboard/containers/MessageManagement/SmsMessage/Loadable';

/* ------------------------------------------------Order Management--------------------------------------- */
import { OrderManagement } from './containers/Dashboard/containers/OrderManagement/Loadable';
import { CreateNewOrder } from './containers/Dashboard/containers/OrderManagement/CreateNewOrder/Loadable';
import ViewOrderInvoice from './containers/Dashboard/containers/OrderManagement/ViewInvoice';
/* --------------------------------SOCIAL MEDIA LINKS------------------------------------------ */
import {CreateSocialMediaLinks} from './containers/Dashboard/containers/Settings/SocialMediaLinks/CreateSocialMedia/Loadable';
import {SocialMediaLinksModule} from './containers/Dashboard/containers/Settings/SocialMediaLinks/Loadable';

/* --------------------------------CONTACT US------------------------------------------ */
import {ContactUs} from './containers/Dashboard/containers/ContactUs/Loadable';

import history from '../utils/history';

import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { sliceKey, reducer, actions } from './containers/Dashboard/slice';
import { userinfoSaga } from './containers/Dashboard/saga';
import { useSelector, useDispatch } from 'react-redux';
import { selectPrivilegeResponse ,selectisPrivilegeRequesting} from './containers/Dashboard/selectors';

import { menuUUID } from 'Constants/uris'

const Dashboard = lazy(() => import('./containers/Dashboard/Loadable'));

export const App: React.FC = () => {
	useInjectReducer({ key: sliceKey, reducer: reducer });
	useInjectSaga({ key: sliceKey, saga: userinfoSaga });
	const dispatch = useDispatch();
	useEffect(() => {
	localStorage.getItem('token')!==null&&dispatch(actions.getAdminPrivilegeRequest());
	}, []);
	const assignPrivilege = useSelector(selectPrivilegeResponse);
	const isRequesting =useSelector(selectisPrivilegeRequesting)

	const [ availableUUID, setavailableUUID ] = useState<any>([]);
	useEffect(
		() => {
			if (Object.keys(assignPrivilege).length > 0) {
				const filteredMenu:any = [];
				assignPrivilege.dataList.map((eachPrivilege,eachPrivilegeIndex)=>{
					if(eachPrivilege.childList.length>0){
						filteredMenu.push(assignPrivilege.dataList[eachPrivilegeIndex].uuid)
					eachPrivilege.childList.map((y)=>filteredMenu.push(y.uuid))
					} else {
						filteredMenu.push(eachPrivilege.uuid)
					}
				})
				setavailableUUID(filteredMenu)
			} 
		},
		[ assignPrivilege.dataList ]
	);
	const haveAccessRoute: any=(uuid?:any)=>{
		return availableUUID.includes(uuid)?true:false
	}
	return (
		<Suspense fallback={<div>Loading...........</div>}>
			<BrowserRouter>
				<Helmet titleTemplate="%s - Procart" defaultTitle="Procart">
					<meta name="description" content="Procart" />
				</Helmet>
				{/* {window.location.pathname === superadminRoute && <Redirect to={superadminRoute} />} */}
				{isRequesting && <Spinner />}
				{(availableUUID.length > 0||localStorage.getItem('token') ===null) &&!isRequesting&&(
					<Router history={history}>
						<Switch>
							<Route exact path="/" component={Login} />
							<Route exact path="/blogs" component={Blog} />
							{/* -----------------------------------Product Management------------------------ */}
							<AdminRoute
								exact
								component={ProductCategoryModule}
								path={`${superadminRoute}/product-category`}
								passRoute={haveAccessRoute(menuUUID.productCategory)}
								/>
							<AdminRoute
								exact
								component={CreateNewCatgory}
								path={`${superadminRoute}/product-category/create`}
								passRoute={haveAccessRoute(menuUUID.productCategory)}
								/>
							<AdminRoute
								exact
								component={CreateNewCatgory}
								path={`${superadminRoute}/product-category/edit/:id`}
								passRoute={haveAccessRoute(menuUUID.productCategory)}
								/>
							<AdminRoute
								exact
								component={HomapageProductModule}
								path={`${superadminRoute}/homepage-product`}
								passRoute={haveAccessRoute(menuUUID.homepageProduct)}
								/>
							<AdminRoute 
								exact 
								path={`${superadminRoute}/product`} 
								component={ProductModule} 
								passRoute={haveAccessRoute(menuUUID.allProducts)}
								/>
							<AdminRoute
								exact
								component={CreateProduct}
								path={`${superadminRoute}/product/create`}
								passRoute={haveAccessRoute(menuUUID.allProducts)}
								/>
							<AdminRoute
								exact
								component={CreateProduct}
								path={`${superadminRoute}/product/edit/:id`}
								passRoute={haveAccessRoute(menuUUID.allProducts)}
								/>
							<AdminRoute
								exact
								component={ProductBrandModule}
								path={`${superadminRoute}/product-brand`}
								passRoute={haveAccessRoute(menuUUID.productBrand)}
								/>
							<AdminRoute
								exact
								component={CreateNewBrand}
								path={`${superadminRoute}/product-brand/create`}
								passRoute={haveAccessRoute(menuUUID.productBrand)}
								/>
							<AdminRoute
								exact
								component={CreateNewBrand}
								path={`${superadminRoute}/product-brand/edit/:id`}
								passRoute={haveAccessRoute(menuUUID.productBrand)}
								/>
							{/* -----------------------------------Message Template------------------------ */}
							<AdminRoute
								exact
								component={MessageTemplateModule}
								path={`${superadminRoute}/message-template`}
								passRoute={haveAccessRoute(menuUUID.notificationTemplate)}
							/>
							<AdminRoute
								exact
								component={EditTemplate}
								path={`${superadminRoute}/message-template/edit/:id`}
								passRoute={haveAccessRoute(menuUUID.notificationTemplate)}
								/>
							<AdminRoute
								exact
								component={ViewMessageTemplateDetail}
								path={`${superadminRoute}/message-template/view/:id`}
								passRoute={haveAccessRoute(menuUUID.notificationTemplate)}
								/>
							{/* -----------------------------------User Management------------------------ */}
				\			<AdminRoute 
								exact 
								path={`${superadminRoute}/users`} 
								component={Users}
								passRoute={haveAccessRoute(menuUUID.adminUsers)}
							/>
							<AdminRoute
								exact
								component={CreateUserForm}
								path={`${superadminRoute}/users/create`}
								passRoute={haveAccessRoute(menuUUID.adminUsers)}
							/>
							<AdminRoute
								exact
								component={CreateUserForm}
								path={`${superadminRoute}/users/edit/:id`}
								passRoute={haveAccessRoute(menuUUID.adminUsers)}
							/>
							<AdminRoute
								exact
								component={ViewUserDetail}
								path={`${superadminRoute}/users/view/:id`}
								passRoute={haveAccessRoute(menuUUID.adminUsers)}
							/>
							{/* -----------------------------------Change & Reset Password------------------------ */}
							<Route exact 
								path="/admin-verify/:id" 
								component={ResetPasswordFlow} 
							/>
							<Route exact 
								path="/reset-password/:id" 
								component={ResetPasswordFlow} 
							/>
							<AdminRoute
								exact
								history={history}
								path={superadminRoute}
								// authenticated={true}
								component={Dashboard}
								// passRoute={true}
								passRoute={haveAccessRoute(menuUUID.dashboard)}
							/>
							<AdminRoute
								exact
								history={history}
								// authenticated={true}
								component={ChangePassword}
								path={`${superadminRoute}/change-password`}
								passRoute={haveAccessRoute(menuUUID.changePassword)}
							/>
							{/* -----------------------------------Tax Setting------------------------ */}
							<AdminRoute
								exact
								// authenticated={authenticate}
								history={history}
								component={TaxSetting}
								path={`${superadminRoute}/tax-setting`}
								passRoute={haveAccessRoute(menuUUID.taxSettings)}
							/>
							{/* -----------------------------------Customer Management------------------------ */}
							<AdminRoute
								exact
								component={CustomerManagement}
								path={`${superadminRoute}/customer-management`}
								passRoute={haveAccessRoute(menuUUID.customers)}
							/>
							<AdminRoute
								exact
								component={CreateNewCustomer}
								passRoute={haveAccessRoute(menuUUID.customers)}
								path={`${superadminRoute}/customer-management/edit/:id`}
							/>
							<AdminRoute
								exact
								component={ViewCustomerDetail}
								passRoute={haveAccessRoute(menuUUID.customers)}
								path={`${superadminRoute}/customer-management/view/:id`}
							/>
							{/* -----------------------------------Menu Management------------------------ */}
							<AdminRoute
								exact
								passRoute={haveAccessRoute(menuUUID.menuProduct)}
								component={MenuProductManagementModule}
								path={`${superadminRoute}/menu-product-management`}
							/>
							<AdminRoute 
								exact
								path={`${superadminRoute}/menu-management`} 
								component={MenuModule} 
								passRoute={haveAccessRoute(menuUUID.setupMenu)}
								/>
							<AdminRoute
								exact
								component={CreateMenu}
								passRoute={haveAccessRoute(menuUUID.setupMenu)}
								path={`${superadminRoute}/menu-management/edit/:id`}
							/>
							<AdminRoute
								exact
								component={CreateMenu}
								passRoute={haveAccessRoute(menuUUID.setupMenu)}
								path={`${superadminRoute}/menu-management/create`}
							/>
							{/* -----------------------------------Order Management------------------------ */}
							<AdminRoute
								exact
								component={OrderManagement}
								path={`${superadminRoute}/order-management`}
								passRoute={haveAccessRoute(menuUUID.orders)}
							/>
							<AdminRoute
								exact
								component={CreateNewOrder}
								passRoute={haveAccessRoute(menuUUID.orders)}
								path={`${superadminRoute}/order-management/edit/:id`}
							/>
							<AdminRoute
								exact
								component={ViewOrderInvoice}
								passRoute={haveAccessRoute(menuUUID.orders)}
								path={`${superadminRoute}/order-management/invoice/:id`}
							/>
							{/* -----------------------------------Banner------------------------ */}
							<AdminRoute 
								exact 
								path={`${superadminRoute}/banner`} 
								component={BannerModule} 
								passRoute={haveAccessRoute(menuUUID.webBanners)}
								/>
							<AdminRoute
								exact
								component={CreateBannerModule}
								path={`${superadminRoute}/banner/create`}
								passRoute={haveAccessRoute(menuUUID.webBanners)}
							/>
							<AdminRoute
								exact
								component={CreateBannerModule}
								path={`${superadminRoute}/banner/edit/:id`}
								passRoute={haveAccessRoute(menuUUID.webBanners)}
							/>
							<AdminRoute
								exact
								component={ViewDetails}
								path={`${superadminRoute}/banner/view/:id`}
								passRoute={haveAccessRoute(menuUUID.webBanners)}
							/>
							 {/* ----------------------SOCIAL MEDIA LINKS------------------- */}
							 <AdminRoute 
								exact
								component={SocialMediaLinksModule}
								path={`${superadminRoute}/social-media-links`}
								passRoute={haveAccessRoute(menuUUID.socialMediaLinks)}
								/>
							<AdminRoute 
								exact
								component={CreateSocialMediaLinks}
								path={`${superadminRoute}/social-media-links/create`}
								passRoute={haveAccessRoute(menuUUID.socialMediaLinks)}
								/>
							{/* ----------------------CONTENT TEMPLATE------------------- */}
							<AdminRoute
								exact
								component={ContentTemplate}
								path={`${superadminRoute}/content-template`}
								passRoute={haveAccessRoute(menuUUID.pageTemplate)}
							/>
							<AdminRoute
								exact
								component={CreateContentTemplate}
								passRoute={haveAccessRoute(menuUUID.pageTemplate)}
								path={`${superadminRoute}/content-template/create`}
							/>
							<AdminRoute
								exact
								component={CreateContentTemplate}
								passRoute={haveAccessRoute(menuUUID.pageTemplate)}
								path={`${superadminRoute}/content-template/edit/:id`}
								/>
							{/* ----------------------MESSAGE MANAGEMENT------------------- */}
							<AdminRoute
								exact 
								component={PushMessage}
								path={`${superadminRoute}/push-message`} 
								passRoute={haveAccessRoute(menuUUID.pushNotification)}
							 />
							<AdminRoute
								exact
								component={CreatPushMessage}
								path={`${superadminRoute}/push-message/create`}
								passRoute={haveAccessRoute(menuUUID.pushNotification)}
							/>
							<AdminRoute
								exact
								component={CreatPushMessage}
								path={`${superadminRoute}/push-message/edit/:id`}
								passRoute={haveAccessRoute(menuUUID.pushNotification)}
							/>
							<AdminRoute
								exact
								component={ViewPushMessageDetails}
								path={`${superadminRoute}/push-message/view/:id`}
								passRoute={haveAccessRoute(menuUUID.pushNotification)}
							/>
							<AdminRoute 
								exact
								path={`${superadminRoute}/sms`} 
								component={SmsMessage} 
								passRoute={haveAccessRoute(menuUUID.sms)}
								/>
							<AdminRoute 
								exact 
								component={CreatSmsMessage} 
								path={`${superadminRoute}/sms/create`} 
								passRoute={haveAccessRoute(menuUUID.sms)}
								/>
							<AdminRoute
								exact
								component={CreatSmsMessage}
								path={`${superadminRoute}/sms/edit/:id`}
								passRoute={haveAccessRoute(menuUUID.sms)}
								/>
							<AdminRoute
								exact
								component={ViewSMSMessageDetails}
								path={`${superadminRoute}/sms/view/:id`}
								passRoute={haveAccessRoute(menuUUID.sms)}
							/>
							<AdminRoute 
								exact 
								component={EmailMessage}
								path={`${superadminRoute}/email-message`}
								passRoute={haveAccessRoute(menuUUID.email)}
							 />
							<AdminRoute
								exact
								component={CreatEmailMessage}
								passRoute={haveAccessRoute(menuUUID.email)}
								path={`${superadminRoute}/email-message/create`}
							/>
							<AdminRoute
								exact
								component={CreatEmailMessage}
								passRoute={haveAccessRoute(menuUUID.email)}
								path={`${superadminRoute}/email-message/edit/:id`}
							/>
							<AdminRoute
								exact
								component={ViewEmailMessageDetails}
								passRoute={haveAccessRoute(menuUUID.email)}
								path={`${superadminRoute}/email-message/view/:id`}
							/>

							{/* -----------------------------------FAQ------------------------ */}
							<AdminRoute 
								exact 
								component={FaqGetModule} 
								passRoute={haveAccessRoute(menuUUID.customerFaq)}
								path={`${superadminRoute}/faqs`} 
							/>
							<AdminRoute
								exact
								component={CreateFaqModule}
								path={`${superadminRoute}/faqs/create`}
								passRoute={haveAccessRoute(menuUUID.customerFaq)}
							/>
							<AdminRoute
								exact
								component={CreateFaqModule}
								path={`${superadminRoute}/faqs/edit/:id`}
								passRoute={haveAccessRoute(menuUUID.customerFaq)}
							/>
							<AdminRoute
								exact
								component={ViewFaqDetails}
								path={`${superadminRoute}/faqs/view/:id`}
								passRoute={haveAccessRoute(menuUUID.customerFaq)}
							/>

						{/* -----------------------------------CONTACT US------------------------ */}
						<AdminRoute 
								exact 
								component={ContactUs} 
								passRoute={haveAccessRoute(menuUUID.contactUs)}
								path={`${superadminRoute}/contact-us`} 
							/>

							<Route component={NotFoundPage} />
						</Switch>
					</Router>
				)}
				<GlobalStyle />
			</BrowserRouter>
		</Suspense>
	);
};
