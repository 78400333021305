import React, { useState, useEffect } from 'react';
import {
 
  ViewFormGroup,
  FormLabel,
  Form,
} from 'app/components/FormComponents/FormElements';
import { Link } from 'react-router-dom';
import { Back } from 'app/components/BaseElements/BaseElements';
import { useSelector, useDispatch } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { sliceKey, reducer, templateActions as actions } from './slice';

import { messageTemplateSaga } from './saga';
import {
  selectSingleData,
} from './selectors';
import { Container, Row, Col } from 'react-bootstrap';

import DashboardHeader from 'app/components/DashboardHeader';
import { Card } from 'app/components/CardComponents';

interface Props {
  match: any;
}

const ViewTemplateDetail: React.FunctionComponent<Props> = props => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: messageTemplateSaga });

  const dispatch = useDispatch();

  const [templateData, settemplateData] = useState<any>({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    password: '',
    confirmPassword: '',
    type: ', ',
    isActive: '',
  });

  const templateDetail = useSelector(selectSingleData);

  useEffect(() => {
    if (templateDetail && Object.keys(templateDetail).length > 0)
      settemplateData({
        ...templateDetail,
      });
  }, [templateDetail]);

  useEffect(() => {
    const id = props.match.params.id;
    if (id) dispatch(actions.getTemplateByIdRequest(id));
    else {
      settemplateData({});
    }
  }, []);
  return (
    <Card>
      <DashboardHeader
        breadCrumb={'Notification Templates'}
        method={'View Details'}
        link={'/message-template'}
      />
      <>
        <Container fluid>
          <Row>
            <Col>
              <Form>
                <Row>
                  <Col lg={4}>
                    <ViewFormGroup>
                      <FormLabel>Event Name: </FormLabel> <b>{templateData?.config?.eventname || ''}</b>

                    </ViewFormGroup>
                  </Col>
                  <Col lg={4}>
                    <ViewFormGroup>
                      <FormLabel>Parameters: </FormLabel>
                      <b> {templateData?.config?.parameters || ''}</b>
                    </ViewFormGroup>
                  </Col>
                  {/* <Col lg={4}>
                    <ViewFormGroup>
                      <FormLabel> Language: </FormLabel>
                      <b> {templateData?.template?.language === 'en' ? 'English' : ''}</b>
                    </ViewFormGroup>
                  </Col> */}

                <Col lg={4}>
                    <ViewFormGroup>
                      <FormLabel> Status: </FormLabel>
                      <b> {templateData?.template?.active === true ? "True" : "False"}</b>
                    </ViewFormGroup>
                  </Col>
                </Row>
                {templateData?.config?.sms === true && (
                  <Card className="bordered">
                    <Row>
                      <Col>
                        <p>
                          <b>SMS DETAILS</b>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div>
                          <FormLabel> <b>SMS Body</b></FormLabel>
                          <p>
                            {templateData?.template?.smsbody || ''}
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                )}
                {templateData?.config?.email === true && (
                  <Card  className="bordered">
                    <Row>
                      <Col>
                        <p>
                          <b>EMAIL DETAILS</b>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <p>
                          <FormLabel>Email Template Name:</FormLabel>
                          <b> {templateData?.template?.emailtemplatename || ''}</b>
                        </p>
                      </Col>
                      <Col lg={6}>
                        <div>
                          <FormLabel>Email Subject:</FormLabel>
                          <b> {templateData?.template?.emailsubject || ''}</b>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div>
                          <FormLabel><b>Email Body:</b></FormLabel>
                          <p dangerouslySetInnerHTML={{ __html: templateData?.template?.emailbody }} />
                        </div>
                      </Col>
                    </Row>
                  </Card>
                )}
                {templateData?.config?.messageunit === true && (
                  <Card  className="bordered">
                    <Row>
                      <Col>
                        <p>
                          <b>MESSAGE UNIT DETAILS</b>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div>
                          <FormLabel>Message Unit Title:</FormLabel>
                          <b>{templateData?.template?.messageunittitle || ''}</b>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div>
                          <FormLabel><b>Message Unit Body:</b></FormLabel>
                          <p>
                            {templateData?.template?.messageunitbody || ''}
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                )}
                {templateData?.config?.push === true && (
                  <Card  className="bordered" >
                    <Row>
                      <Col>
                        <p>
                          <b>PUSH NOTIFICATIONS DETAILS</b>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p>
                          <FormLabel><b>Push Notification Title:</b></FormLabel>
                           {templateData?.template?.pushtitle || ''}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div>
                          <p><b>Push Notification Logo:</b></p>
                          {/* <p>
                        <b>{templateData?.template?.pushmedia || ''}</b>
                      </p> */}
                          {templateData?.template?.pushmedia && (
                            <img src={templateData?.template?.pushmedia} alt='Push Notification Image' style={{ height: "10%", width: "20%" }} />
                          )}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div>
                          <FormLabel><b>Push Body:</b></FormLabel>
                          <p>
                            {templateData?.template?.pushbody || ''}
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                )}
              </Form>
            </Col>

          </Row>
          <Row>
            <Col>
              <Link to={`/admin/dashboard/message-template`}>
                <Back>Back </Back>
              </Link>
            </Col>
          </Row>
        </Container>
      </>{' '}

    </Card>
  );
};

export default ViewTemplateDetail;
