import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
*{
  margin:0;
  padding:0;
  outline:0;
  box-sizing:border-box;
}
  html,
  body {
    height: 100%;
    width: 100%;
  }

  body {
    font-family: 'Open Sans', sans-serif;
    background: #f9f9f9;
  }

  #root {
    min-height: 100%;
    min-width: 100%;
  }

  p,
  label {
    line-height: 1.5em;
    color:#373a3c;
  }

  h1,h2,h3,h4,h5,h6{
    color:#373a3c;
  }

  input, select {
    font-family: inherit;
    font-size: inherit;
  }
a{
  &:hover{ text-decoration:none;}
 
}
 .table td , .table th{
  padding: 0.5rem 0.75rem;
 }
`;

