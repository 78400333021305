import React from 'react';
import styled from 'styled-components';


import Breadcrumb from '../Breadcrumb';
import PageTitle from "../PageTitle";


const DashboardHeaderWrap = styled.div `
  display:flex;
  justify-content:space-between;
  align-items:flex-end;
  margin-bottom:30px;
  > div{
    width:50%;
    margin:0;
    margin-right:10px;
    @media screen and (max-width:920px){
      width:100%;
    }
  }
  @media screen and (max-width:475px){
    display:block;
    margin-bottom:15px;
    > div{
      margin:0;
      margin-bottom:10px;
    }
  } 
  
`
interface Props{
  breadCrumb?: any;
  method?: any;
  link?: any;
  dashboardSubtitle?: any;
}

// function DashboardHeader({breadCrumb}: Props) {
  const DashboardHeader: React.FunctionComponent<Props> = props => {
  return (
    <DashboardHeaderWrap>
        <PageTitle pageTitle = {props.breadCrumb ? props.breadCrumb: 'Admin Dashboard'}/>
        <Breadcrumb breadCrumb={props.breadCrumb} method={props.method ? props.method: ''} link={props.link? props.link: ''}/>
    </DashboardHeaderWrap>
  );
}

export default DashboardHeader;
